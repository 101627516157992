<div  id="article">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="">
                    <ul #nav="ngbNav" ngbNav [activeId]="route.fragment | async" class="nav-tabs d-flex justify-content-start" orientation="horizontal">
                        <li [ngbNavItem]="'all'">
                            <a ngbNavLink >All</a>
                            <ng-template ngbNavContent>
                                <div class="row">
                                    <div class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" style="height: fit-content;">
                                        <div *ngFor="let item of articleList; index as i">
                                            <div class="card" *ngIf="i%2 == 0" >
                                                <div class="img"*ngIf="item.thumbnail_image ">
                                                    <img src="{{this.filePath+item.thumbnail_image}}" alt="" >
                                                </div>
                                                <div class="text">
                                                    <h1>{{item.title[this.ind].content}}</h1>
                                                    <div class="date">
                                                        <img alt="" >
                                                        &ensp; &ensp; &nbsp;&nbsp;
                                                        {{item.updated_at |date:'MMM d'}}&nbsp;&nbsp;
                                                        <!--                            <i>{{item.card}}</i>-->
                                                    </div>

                                                    <p>{{item.short_description[this.ind].content}}</p>
                                                    <a >
                                                        &ensp; &ensp; &nbsp;&nbsp;
                                                        <img  alt="" class="icon">
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" style="height: fit-content;">
                                        <div *ngFor="let item of articleList; index as i">
                                            <div class="card" *ngIf="i%2 !== 0" >
                                                <div class="img"*ngIf="item.thumbnail_image ">
                                                    <img src="{{this.filePath+item.thumbnail_image}}" alt="" >
                                                </div>
                                                <div class="text">
                                                    <h1>{{item.title[this.ind].content}}</h1>
                                                    <div class="date">
                                                        <img alt="" >
                                                        Last update &ensp; &ensp; &nbsp;&nbsp;{{item.updated_at |date:'MMM d'}}&nbsp;&nbsp;
                                                        <!--                            <i>{{item.card}}</i>-->
                                                    </div>

                                                    <p>{{item.short_description[this.ind].content}}</p>
                                                    <a>
                                                        more details &ensp; &ensp; &nbsp;&nbsp;
                                                        <img  alt="" class="icon">
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>


                        <li *ngFor="let tab of tabsArticle;index as j" ngbNavItem="{{tab.art}}">
                            <a ngbNavLink >{{tab.name.en}}</a>
                            <ng-template ngbNavContent>
                                <div class="row">
                                    <div class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" style="height: fit-content;">
                                        <div *ngFor="let item of tabs[tab.art]; index as i">
                                            <div class="card" *ngIf="i%2 == 0" >
                                                <div class="img"*ngIf="item.thumbnail_image ">
                                                    <img src="{{this.filePath+item.thumbnail_image}}" alt="" >
                                                </div>
                                                <div class="text">
                                                    <h1>{{item.title[this.ind].content}}</h1>
                                                    <div class="date">
                                                        <img alt="">
                                                        &ensp; &ensp; &nbsp;&nbsp;
                                                        {{item.updated_at |date:'MMM d'}}&nbsp;&nbsp;
                                                        <!--                            <i>{{item.card}}</i>-->
                                                    </div>

                                                    <p>{{item.short_description[this.ind].content}}</p>
                                                    <a >
                                                        &ensp; &ensp; &nbsp;&nbsp;
                                                        <img  alt="" class="icon">
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" style="height: fit-content;">
                                        <div *ngFor="let item of tabs[tab.art]; index as i">
                                            <div class="card" *ngIf="i%2 !== 0" >
                                                <div class="img"*ngIf="item.thumbnail_image ">
                                                    <img src="{{this.filePath+item.thumbnail_image}}" alt="" >
                                                </div>
                                                <div class="text">
                                                    <h1>{{item.title[this.ind].content}}</h1>
                                                    <div class="date">
                                                        <img alt="">
                                                        Last update &ensp; &ensp; &nbsp;&nbsp;{{item.updated_at |date:'MMM d'}}&nbsp;&nbsp;
                                                        <!--                            <i>{{item.card}}</i>-->
                                                    </div>

                                                    <p>{{item.short_description[this.ind].content}}</p>
                                                    <a >
                                                        more details &ensp; &ensp; &nbsp;&nbsp;
                                                        <img  alt="" class="icon">
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="ms-4"></div>
                </div>
            </div>
        </div>
    </div>
</div>
