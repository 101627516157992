<footer id="footer">
  <div class="container-fluid">
    <div class="row text-white bg-dark text-center black">
      <p class="col-4"><img src="assets/images/footerIcon/Call%20(1).png" alt="call"> ( 374) 60 38 88 88</p>
      <p class="col-4"><img src="assets/images/footerIcon/Combined-Shape.png" alt="call">19 Charents Street, Yerevan</p>
      <p class="col-4"><img alt="" src="../../../../assets/images/footerIcon/Group%206.svg">
        <img alt="" src="../../../../assets/images/footerIcon/Group%205.svg">
        <img alt="" src="../../../../assets/images/footerIcon/Group%204.svg"></p>
    </div>
  </div>
  <div class="container ">
    <div class="row section2">
      <div class="col-9	col-sm-9	col-md-3 col-lg-3	col-xl-3">
        <img src="assets/images/nav/Logo.svg" alt="">
        <p>Mellat Bank is controlled by the Central Bank of Armenia</p>
        <p>All rights reserved <a href="/">Mellat Bank</a> 2021</p>
      </div>
      <div class="col-4	col-sm-4	col-md-3 col-lg-3	col-xl-3">
        <ul>
          <li><a href=""><b>About Bank</b></a></li>
          <li><a href="">General Meeting of Bank</a></li>
          <li><a href="">Shareholders and Investors</a></li>
          <li><a href="">Dividents</a></li>
          <li><a href="">Reports</a></li>
          <li><a href="">Oficial Documents</a></li>
        </ul>
      </div>
      <div class="col-4	col-sm-4	col-md-3 col-lg-3	col-xl-3">
        <ul>
          <li><a href=""><b>News</b></a></li>
          <li><a href="">Disposed Property</a></li>
          <li><a href="">Announcements</a></li>
          <li><a href="">Vacancies</a></li>
        </ul>
      </div>
      <div class="col-4	col-sm-4	col-md-3 col-lg-3	col-xl-3">
        <ul>
          <li><a href=""><b>Additional Information</b></a></li>
          <li><a href="">Customer Rights</a></li>
          <li><a href="">Useful References</a></li>
          <li><a href="">Terms</a></li>
          <li><a href="">Privaty Policy</a></li>
          <li><a href="">Contact Us</a></li>
        </ul>
      </div>
    </div>
    <div class="row section3">
      <div class="col-12	col-sm-12	col-md-6 col-lg-6	col-xl-6">
        <p>The Bank is not responsible for the accuracy and reliability of the content of the linked sites,
          the ads therein, and the Bank is not responsible for the possible consequences of the use of the
          information contained therein. In case of any discrepancies in the information published in
          different languages, the Armenian version should be followed.</p>
      </div>
      <div class="col-12	col-sm-12	col-md-6 col-lg-6	col-xl-6">
        <div class="row icons">
          <div class="col-6	col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <img alt="" src="../../../../assets/images/footerIcon/image%202.svg">
          </div>
          <div class="col-6	col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <img alt="" src="../../../../assets/images/footerIcon/image%203.svg">
          </div>
          <div class="col-6	col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <img alt="" src="../../../../assets/images/footerIcon/image%204.svg">
          </div>
          <div class="col-6	col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <img alt="" src="../../../../assets/images/footerIcon/image%205.svg">
          </div>
        </div>
      </div>
    </div>
  </div>

</footer>
