export const FORM_TYPES = {
    SELECT_BOX: 'select',
    TEXT_INPUT: 'text',
    CURRENCY_INPUT: 'textt',
    NUMBER_INPUT: 'number',
    PASSWORD_INPUT: 'password',
    EMAIL_INPUT: 'email',
    PHONE_INPUT: 'phone',
    CHECKBOX: 'checkbox',
    SWITCH_INPUT: 'switch',
    MULTISELECT_INPUT: 'multiselect',
    TEXTAREA_INPUT: 'textarea',
    REACH_TEXT_INPUT: 'reach-text',
    DATE_INPUT: 'date',
    TIME_INPUT: 'timepicker',
    DATE_RANGE_INPUT: 'date-range',
    SEARCH_INPUT: 'search',
    CHOOSE_FILE: 'choose-file',
    CHOOSE_FILES: 'choose-files',
    AUTOCOMPLETE_INPUT: 'autocomplete',
    COLOR_INPUT: 'color'
};
