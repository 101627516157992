import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";

@Component({
  selector: 'app-deposit-calculator',
  templateUrl: './deposit-calculator.component.html',
  styleUrls: ['./deposit-calculator.component.scss']
})
export class DepositCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
