<div id="about">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-12	col-sm-12 col-lg-6	col-xl-6">
        <div class="images">
          <img alt="" class="rounded fit-picture" src="../../../../assets/images/img/image%2018%20.png">
          <img alt="" class="rounded" src="../../../../assets/images/img/image%201.png">
          <img alt="" class="rounded" src="../../../../assets/images/img/image%2019.png">
        </div>

      </div>
      <div class="col-md-6 col-12	col-sm-12	col-lg-6	col-xl-6">
        <div >
          <h1>About us</h1>
          <p>“Mellat Bank” Closed Joint-stock Company was established in May 1992 based on joint declaration about
            “Friendship and Cooperation”
            between the Republic of Armenia and Islamic Republic of Iran, according to the license agreement between the
            Central Bank of the RA
            and Iranian “Mellat” state bank as a branch of the “Mellat” bank of IRI in Yerevan. </p>
          <a>
            Learn more
            <i class="fa fa-arrow-right"></i>

          </a>

        </div>
      </div>
    </div>
  </div>
</div>
