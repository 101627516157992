import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-individuals',
  templateUrl: './individuals.component.html',
  styleUrls: ['./individuals.component.scss']
})
export class IndividualsComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }
}
