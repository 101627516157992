<div id="news">
  <div class="container">
    <div class="title">
      <h1>Latest news</h1>
    </div>
    <div class="row">
      <div
              *ngFor="let news of NEWS; index as i"
              class="col-lg-3 col-md-6 col-6 col-sm-6 col-xl-3">
        <div class="card">
          <div class="img" *ngIf="news.thumbnail_image" [ngStyle]="{'background-image':'url('+ mainPath+news.thumbnail_image+')'}"></div>
          <p class="date">{{news.updated_at |date:'MMM d'}}</p>
          <p>{{news.title[0].content}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
