<section class="container">
  <div class="title">
      <h1>Flexible offers for everyone</h1>

  </div>
  <div class="row">
    <div class="col-md-6 col-12	col-sm-6 col-lg-6	col-xl-6">
      <div class="card">
        <img src="../../../../assets/images/img/image24.png" alt="">
        <div class="">
          <h2 class="">Individuals</h2>
          <button class="btn"><a ><img src="../../../../assets/images/img/Arrow%20-%20Right%20Circle.png" alt=""></a></button>
        </div>


      </div>
    </div>
    <div class="col-md-6 col-12	col-sm-6 col-lg-6	col-xl-6">
      <div class="card">
        <img src="../../../../assets/images/img/image25.png" alt="">
        <div class="">
          <h2 class="">Businesses</h2>
          <button class="btn"><a ><img src="../../../../assets/images/img/Arrow%20-%20Right%20Circle.png" alt=""></a></button>
        </div>


      </div>
    </div>


  </div>
</section>
