<div id="carousel">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ngb-carousel *ngIf="text" [showNavigationArrows]="showNavigationArrows"
                      [showNavigationIndicators]="showNavigationIndicators">
          <ng-template *ngFor="let txt of text" ngbSlide>
            <div class="main container">
              <div class="row">
                <div class="col-6 text " >
                  <div class="float-left  ">
                    <div style="margin: auto 0">
                      <h1>{{txt.title}}</h1>
                      <p>{{txt.text}}</p>
                      <ul>
                        <li *ngFor="let list of txt.list"><h3> </h3> &nbsp;&nbsp;{{list}}</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>

            </div>




          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>


</div>

