


<div class="block">
  <div class="images-list">
    <div  *ngFor="let item of  data "
          class="module {{item.class}}" [ngClass]="selectedItemsIndexArr.includes(item.id) ? 'selected-img': ''">
      <div class="actions">
        <button class="edit-delete" (click)="editDelete(item.id,item)">
          <i class="far fa-eye"></i>
        </button>
      </div>
      <img src="{{item.s3Url}}"  (click)="selectItem(item.id,item)"  alt="">
    </div>
  </div>
  <div class="show-more" *ngIf="next"> <button (click)="onLoadMore()">Load More</button> </div>
</div>



