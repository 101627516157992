import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-files-package',
  templateUrl: './files-package.component.html',
  styleUrls: ['./files-package.component.scss']
})
export class FilesPackageComponent implements OnInit {
  active = 'History';
  @Input() package;
  files = [];
  mainPath = environment.filePath;
  tabName = ['History','Bank significant paricipants','Managers','Company','Achivements','Corporate governance','Human resources','Missions']
  constructor() { }

  ngOnInit(): void {
    this.files = this.package.files;
  }

}
