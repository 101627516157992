import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-questions-bar',
  templateUrl: './questions-bar.component.html',
  styleUrls: ['./questions-bar.component.scss']
})
export class QuestionsBarComponent implements OnInit {
  @Input() questions;
  public type :string

  constructor() {
  }

  ngOnInit(): void {
    this.type = this.questions.type
  }

  open(collapse: HTMLDivElement, fa: HTMLElement) {
    collapse.classList.toggle('d-block')
    fa.classList.toggle('fa-angle-down')
  }
}
