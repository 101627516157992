import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
    selector: 'app-validation-error',
    templateUrl: './validation-error.component.html'
})
export class ValidationErrorComponent implements OnInit, OnChanges {

    @Input() item;
    @Input() customMessage: String;
    public errors = [];

    constructor() {

    }

    ngOnChanges() {
        const selfObject: any = this;
        const errors: any = [];
        selfObject.errors = [];
        this.item.valueChanges.subscribe(function (e) {
            errors.splice(0, errors.length);
            if (errors && selfObject.item.touched) {
                for (const key in selfObject.item.errors) {
                    if (selfObject.item.errors[key]) {
                        errors[errors.length] = key;
                    }
                }
            }
            selfObject.errors = errors;
        });
    }

    ngOnInit() {
        const selfObject: any = this;
        const errors: any = [];
        selfObject.errors = [];
        this.item.valueChanges.subscribe(function (e) {
            errors.splice(0, errors.length);
            if (errors) {
                for (const key in selfObject.item.errors) {
                    if (selfObject.item.errors[key]) {
                        errors[errors.length] = key;
                    }
                }
            }
            selfObject.errors = errors;
        });
    }


}
