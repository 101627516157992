<div id="tab">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="d-flex flex-row-reverse">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                        <li><h1>Related pages</h1></li>
                        <li *ngFor="let tab of tabName;" ngbNavItem="{{tab}}">
                            <a ngbNavLink>{{tab}}</a>
                            <ng-template ngbNavContent>
                                <div class="s1">
                                    <h1>How it started</h1>
                                    <p>
                                        “Mellat Bank” Closed Joint-stock Company was established in May 1992 based on joint declaration about
                                        “Friendship and Cooperation” between the Republic of Armenia and Islamic Republic of Iran, according to
                                        the license agreement between the Central Bank of the RA and Iranian “Mellat” state bank as a branch of
                                        the “Mellat” bank of IRI in Yerevan. The branch commenced its operations in 1995 in accordance with License
                                        H-OM-1/4issued by the Central Bank of the RA on 10 January 1995.
                                        <br><br>
                                        On 02.09.1997 Yerevan branch was reorganized into subsidiary bank closed joint-stock company in a separate
                                        legal identity, and on 27.07.2005 was renamed as a closed joint-stock company.
                                    </p>
                                </div>
                                <div class="s2">
                                    <ul class="download">
                                        <li>
                                            <img src="assets/images/img/Downlaod.png" alt="">
                                            <a href="/assets/images/img/Downlaod.png" download>Downlaod.png</a>
                                        </li>
                                        <li>
                                            <img src="assets/images/img/Downlaod.png" alt="">
                                            <a href="/assets/images/img/Downlaod.png" download>Downlaod.png</a>
                                        </li>
                                        <li>
                                            <img src="assets/images/img/Downlaod.png" alt="">
                                            <a href="/assets/images/img/Downlaod.png" download>Downlaod.png</a>
                                        </li>
                                        <li>
                                            <img src="assets/images/img/Downlaod.png" alt="">
                                            <a href="/assets/images/img/Downlaod.png" download>Downlaod.png</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="s3">
                                    <h1>What’s next</h1>
                                    <p>Mellat Bank CJSC is registered with Central Bank of the RA under registration number 78.
                                        Its authorized capital amounts to AMD 32,100,000,000, which was provided by “Mellat” bank of IRI.
                                        “Mellat” bank of IRI is the sole shareholder of Mellat Bank CJSC. Mr. Reza Dolatabadi has been
                                        appointed as Chief executive officer of Bank Mellat I.R. Iran. The Armenian Mellat bank not only has
                                        facilitated trading relations but also participated in a number of projects of both countries,
                                        as well as renders services in Armenia’s internal market.</p>
                                </div>

                            </ng-template>
                        </li>
                    </ul>

                    <div [ngbNavOutlet]="nav" class="ms-4"></div>
                </div>

                <!--        <pre>Active: {{ active }}</pre>-->

            </div>
        </div>
    </div>
</div>
