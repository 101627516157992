import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-loan-calc-carousel',
  templateUrl: './loan-calc-carousel.component.html',
  styleUrls: ['./loan-calc-carousel.component.scss'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers

})
export class LoanCalcCarouselComponent implements OnInit {

  showNavigationArrows = false ;
  showNavigationIndicators = true;

  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit(): void {
  }

  text = [
    {
      title: 'A business loan, \n' +
        'faster then you read this',
      text: 'Get business loans approved within days with transparent lending criteria and transparent processes.',
      list: ['Same day approval and multiple funding options\n' ,
      'Access loans without origination fees\n' ,
      'Borrow up to $250,000']
    },
    {
      title: 'A business loan, \n' +
        'faster then you read this',
      text: 'Get business loans approved within days with transparent lending criteria and transparent processes.',
      list: ['Same day approval and multiple funding options\n' ,
        'Access loans without origination fees\n' ,
        'Borrow up to $250,000']
    },
    {
      title: 'A business loan, \n' +
        'faster then you read this',
      text: 'Get business loans approved within days with transparent lending criteria and transparent processes.',
      list: ['Same day approval and multiple funding options\n' ,
        'Access loans without origination fees\n' ,
        'Borrow up to $250,000']
    }
  ];

}
