import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {StorageService} from './storage.service';
import {appConstants} from '../constants';

@Injectable()
export class SuperadminGuard implements CanActivate {

    constructor(private router: Router) { }

    // tslint:disable-next-line:typedef
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = StorageService.getJsonItem(StorageService.statics.currentUser);
        if (account) {
            return true;
        } else {
            this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
            return false;
        }
    }
}
