<div id="team">
  <div class="container">
    <div class="row">
      <div
              *ngFor="let team of main;"
              class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" >
        <img alt="" src="{{mainPath+team.image}}">
        <p>{{team.position[ind].content}}</p>
        <h3>{{team.name[ind].content}}</h3>
      </div>

    </div>
    <div class="row">
      <div
              *ngFor="let team of secont;"
              class="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2">
        <img alt="" src="{{mainPath+team.image}}">
        <p>{{team.position[ind].content}}</p>
        <h3>{{team.name[ind].content}}</h3>
      </div>
    </div>
  </div>
</div>
