import {StorageService} from './storage.service';


export class ResponseHelper {

    public errorCodeMap = {
        depricatedSession: 1000401,
        // 1000020: 'Account is not activated',
    };
    public successCode = 200;
    public errorCode = 400;
    public errorCode2 = 500;

    public response;
    public messages;
    public data;
    public errorMessages;
    public hasError;

    static errorHandler(error) {
    }

    constructor(res: any) {
        this.response = res;
        const currentStatus: number = res.status;
        this.hasError = false;
        switch (currentStatus) {
            case this.successCode :
                this.catchForSuccess(res.result, res.message);
                break;
            case this.errorCode :
                this.catchForError(res.message);
                this.hasError = true;
                break;
            case this.errorCode2 :
                this.catchForError(res.message);
                this.hasError = true;
                break;
            default:
                break;
        }
        if (this.errorMessages && this.errorMessages.length > 0 ) {
            if (Number(this.errorCode) === this.errorCodeMap.depricatedSession) {
                StorageService.removerItem(StorageService.statics.currentUser);
                StorageService.removerItem(StorageService.statics.role);
                location.reload();
            }
        }
    }

    catchForSuccess(data, message) {
        if (!data || data === undefined) {
            data = [];
        }
        if (!message || message === undefined) {
            message = [];
        }

        this.data = data;

        if (message.length > 0) {
            this.messages = message;
        }
        this.hasError = false;
    }

    catchForError(messages) {
        if (!messages || messages === undefined) {
            messages = {msg: 'Unhandled error'};
        }
        if (Array.isArray(messages)) {
            this.errorMessages = messages[0].msg;
            this.errorCode = messages[0].code;
        } else {
            this.errorMessages = messages.msg;
            this.errorCode = messages.code;
        }
    }


    getMessages() {
        return this.messages;
    }

    getData() {
        return this.data;
    }

    getDataForEntity() {

    }

    getErrorMessages() {
        return this.errorMessages;
    }
}
