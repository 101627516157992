import {Component, OnInit} from '@angular/core';
import {EventManager} from '../../../../core/helpers/event-manager';

@Component({
    selector: 'app-mask-loader',
    templateUrl: './mask-loader.component.html',
    styleUrls: ['./mask-loader.component.scss'],
})
export class MaskLoaderComponent implements OnInit {

    public showMask = false;

    constructor(eventsService: EventManager) {
        eventsService.loaderShowEmitter.subscribe(item => this.showLoading());
        eventsService.loaderHideEmitter.subscribe(item => this.hideLoading());
    }

    ngOnInit() {

    }

    showLoading() {
        this.showMask = true;
    }

    hideLoading() {
        this.showMask = false;
    }


}
