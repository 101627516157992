import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnInit {

  @Output() changeInput = new EventEmitter();
  @Input() type: 'text'| string;
  @Input() inputType: 'input'| 'textArea'| string;
  @Input() labelText: string;
  @Input() placeHolder: string;
  @Input() value: string | undefined;
  @Input() id: string;
  @Input() class: string;
  @Input() hasError: boolean = false;
  @Input() required: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

  change(event:any):void{
    this.changeInput.emit(event)
  }


}
