
  <div class="form">
    <input type="text" (change)="change($event)" id="text" [value]="value || ''" name="text" class="input {{class}}" autocomplete="off" required />
    <label for="text" class="label-name">
    <span class="content-name">
      {{labelText}}
    </span>
    </label>

  </div>
  <div class="error" *ngIf="hasError"> Title is required </div>



