import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';


@Injectable()

export class PopupHelperService {
  constructor(){
    //PopupHelper.eventManager = this.eventManager
  }



  confirmationPopup(id:string,cb:any) {
    Swal.fire({
      title: "Good job!",
      text: "You clicked the button!",
      icon: "success",
      showCloseButton: true,
      customClass:{container:"bg-000"},
      showCancelButton: true,
    }).then((willDelete:any) => {

      if (willDelete.dismiss) {
        Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        console.log('deleted swal')
        // cb.bind(this.eventManager)(id);
      }
    });
  }


  printSuccess(text:string,customClass:any) {
    Swal.fire({title:'',
      text,
      icon: 'info',
      confirmButtonText: 'Ok',
      customClass
    });
  }

  printError(text:string) {
    Swal.fire('', text, 'error');
  }

  printQuestion(popupSettings:any,id:string | undefined,cb:any,context:any) {
    Swal.fire(popupSettings).then((willDelete:any) => {
      if (!willDelete.dismiss) {
        cb.bind(context)(id);
      }
    })
  }

}
