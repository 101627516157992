<div id="calculator">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-12 col-sm-12 col-xl-6" style="padding: 4% 9%">
        <div class="card">
          <h1>Calculate</h1>
          <div class="form-group ">
            <p class=" ">I have</p>
            <div class="input-group mb-3">
              <input (keyup)="onKeyUp($event)" aria-label="Text input with dropdown button" class="form-control"
                     name="Have" type="number" value="{{have}}">
              <div class="dropdown" ngbDropdown>
                <button aria-expanded="false" class="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton1"
                        ngbDropdownToggle type="button">
                  <img src="assets/images/img/{{selectedHaveCurrencyFlag}}" alt="" >
                  {{selectedHaveCurrency}}
                </button>
                <ul aria-labelledby="dropdownMenuButton1" ngbDropdownMenu>
                  <li  *ngFor="let currency of currencies;index as i" >
                          <div name="selectedHaveCurrency" (click)="selectHave('Have',currency.currency)" ngbDropdownItem  >
                            <img src="assets/images/img/{{currency.flag}}" alt="" >
                            <label>{{currency.currency}}</label>
                          </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          <div class="form-group">
            <p class="text-right "></p>
            <p class="text-left">I want</p>
            <div class="input-group mb-3">
              <input (keyup)="onKeyUp($event)" aria-label="Text input with dropdown button" class="form-control"
                     name="want" type="number" value="{{want}}">
              <div class="dropdown" ngbDropdown>
                <button aria-expanded="false" class="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton2"
                        ngbDropdownToggle type="button">
                  <img src="assets/images/img/{{selectedWantCurrencyFlag}}" alt="" >
                  {{selectedWantCurrency}}
                </button>
                <ul aria-labelledby="dropdownMenuButton1" ngbDropdownMenu>
                  <li  *ngFor="let currency of currencies;index as i">
                    <div name="selectedWantCurrency" (click)="selectHave('Want',currency.currency)" ngbDropdownItem>
                      <img src="assets/images/img/{{currency.flag}}" alt="" >
                      <label>{{currency.currency}}</label>
                    </div>

                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div class="date">
            <p>last updated:  {{ dateToday | date: "dd.MM.yyyy  HH:mm" }}</p>
          </div>
        </div>


      </div>
      <div class="col-lg-6 col-md-12 col-12 col-sm-12 col-xl-6 currency_data">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Currency</th>
            <th scope="col">Buy</th>
            <th scope="col">Sale</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let currency of currencies; index as i">

            <td *ngIf="i != 0">
              <img [src]="'assets/images/img/' + currency.flag" class="mr-2">
              {{ currency.currency }}
            </td>
            <td *ngIf="i !=0">{{ currency.buy | number }}</td>
            <td *ngIf="i != 0">{{ currency.sale | number }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
