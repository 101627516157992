import {EventEmitter,Output, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EventManager {
    public notificationShowEmitter: EventEmitter<any>;
    public loaderShowEmitter: EventEmitter<any>;
    public loaderHideEmitter: EventEmitter<any>;

    public updateUserEmitter: EventEmitter<any>;
    @Output() socketEmitter: EventEmitter<any>;
    constructor() {
        this.notificationShowEmitter = new EventEmitter();
        this.loaderShowEmitter = new EventEmitter();
        this.loaderHideEmitter = new EventEmitter();
        this.updateUserEmitter = new EventEmitter();

        this.socketEmitter = new EventEmitter();
    }
    showNotification (notification: any): void {
        this.notificationShowEmitter.emit(notification);
    }

    emitSocket(info) {
        this.socketEmitter.emit(info);
    }

    updateBasketFire() {
        this.socketEmitter.emit();
    }

    shiwLoader (): void {
        this.loaderShowEmitter.emit();
    }

    hideLoader (): void {
        this.loaderHideEmitter.emit();
    }

    updateUser (): void {
        this.updateUserEmitter.emit();
    }
}
