import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
title:string | undefined
  breadcrumbs = []
  @Input()
  message: any;

  constructor() { }
  ngOnInit(): void {
    this.select()
  }

  select(){
    this.breadcrumbs = []
    this.title = this.message[0];
    this.message.forEach((element: number, index: string | number)=>{
      if(index !==0)  { // @ts-ignore
        this.breadcrumbs.push(element);
      }
    });
  }





}
