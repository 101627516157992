import {Component, Input, OnInit} from '@angular/core';
interface card {
  title: string;
  data: string;
  card :string;
  text: string;
  category: string;
}
@Component({
  selector: 'app-business-main',
  templateUrl: './business-main.component.html',
  styleUrls: ['./business-main.component.scss']
})
export class BusinessMainComponent implements OnInit {
  CARD : card[] = [
    {
      title: 'Loans',
      data: '2022-01-18',
      card : 'Loans',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly scalable test ' +
        'infrastructure to ensure quality? If you love paying attention to detail and the startup environment, you\'ll love this position.',
      category: 'individual',
    },
    {
      title: 'Accounting',
      data: '2022-02-20',
      card : 'Loans',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a' +
        ' truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the' +
        ' startup environment, you\'ll love this position.',
      category: 'individual',
    },
    {
      title: 'Escrow',
      data: '2022-02-10',
      card : 'Loans',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly' +
        ' scalable test infrastructure to ensure quality? If you love paying attention to detail and the startup' +
        ' environment, you\'ll love this position.',
      category: 'individual',
    },
    {
      title: 'Transfers',
      data: '2022-01-18',
      card : 'Transfers',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a ' +
        'truly scalable test infrastructure to ensure quality? If you love paying attention to detail and' +
        ' the startup environment, you\'ll love this position.',
      category: 'business',
    },
    {
      title: 'Investment',
      data: '2022-02-20',
      card : 'Transfers',
      text: 'We seek excellence in each digital product we craft. Would you like to help our' +
        ' team create a truly scalable test infrastructure to ensure quality? If you love paying ' +
        'attention to detail and the startup environment, you\'ll love this position.',
      category: 'business',
    }

  ];

  cards = this.CARD
  index = ''

  @Input()
  message: any;
  constructor() { }

  ngOnInit(): void {
    this.index = this.message[0];
  }


  get(index: string) {
  this.index = index
  }
}
