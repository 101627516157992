import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PopupHelper} from "../../core/helpers/popup.helper";
import {NotificationsHelper} from "../../core/helpers/notifications.helper";
import {NetworkHelper} from "../../core/helpers/network.helper";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {NgbModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbAccordionModule, NgbNavModule, NgbTooltipModule, NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {IndividualsComponent} from "./servicesPage/individuals/individuals.component";
import {BusinessComponent} from "./servicesPage/business/business.component";
import {BusinessMainComponent} from "./servicesPage/business/business-main/business-main.component";
import {RelatedPageComponent} from "./related-page/related-page.component";
import {NavBarComponent} from "./navBar/navBar.component";
import {SelectLanguageComponent} from "./navBar/select-language/select-language.component";
import {FullNavComponent} from "./navBar/full-nav/full-nav.component";
import {LoanCalcComponent} from "./loan-calculator-page/loan-calc/loan-calc.component";
import {LoanCalcCarouselComponent} from "./loan-calculator-page/loan-calc-carousel/loan-calc-carousel.component";
import {LoanCalculatorPageComponent} from "./loan-calculator-page/loan-calculator-page.component";
import {AboutComponent} from "./about/about.component";
import {CalculatorComponent} from "./calculator/calculator.component";
import {CarouselBarComponent} from "./carousel-bar/carousel-bar.component";
import {MellatServiceComponent} from "./mellat-service/mellat-service.component";
import {NewsComponent} from "./news/news.component";
import {OfferComponent} from "./offer/offer.component";
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {TeamMainComponent} from "./contact/team-page/team-main.component";
import {QuestionsBarComponent} from "./questions-bar/questions-bar.component";
import {ContactMainComponent} from "./contact/contact-page/contact-main.component";
import {ServiceListComponent} from "./servicesList/service-list.component";
import {FilesPackageComponent} from "./files-package/files-package.component";
import {NewsListComponent} from "./news-list/news-list.component";
import {BannerComponent} from "./banner/banner.component";
import {ArticleListComponent} from "./article-list/article-list.component";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbAccordionModule,
        NgbNavModule,
        NgbTooltipModule,
        NgbCarouselModule,
        NgbToastModule,
    ],
    exports: [
        IndividualsComponent,
        BusinessComponent,
        BusinessMainComponent,
        RelatedPageComponent,
        FilesPackageComponent,
        NavBarComponent,
        SelectLanguageComponent,
        FullNavComponent,
        LoanCalcComponent,
        LoanCalcCarouselComponent,
        LoanCalculatorPageComponent,
        AboutComponent,
        CalculatorComponent,
        CarouselBarComponent,
        MellatServiceComponent,
        NewsComponent,
        OfferComponent,
        HeaderComponent,
        FooterComponent,
        ContactMainComponent,
        ServiceListComponent,
        NewsListComponent,
        TeamMainComponent,
        QuestionsBarComponent,
        BannerComponent,
        ArticleListComponent
    ],
    declarations: [
        IndividualsComponent,
        BusinessComponent,
        BusinessMainComponent,
        RelatedPageComponent,
        NavBarComponent,
        FilesPackageComponent,
        SelectLanguageComponent,
        NewsListComponent,
        FullNavComponent,
        LoanCalcComponent,
        LoanCalcCarouselComponent,
        LoanCalculatorPageComponent,
        AboutComponent,
        CalculatorComponent,
        CarouselBarComponent,
        ContactMainComponent,
        MellatServiceComponent,
        NewsComponent,
        ServiceListComponent,
        QuestionsBarComponent,
        OfferComponent,
        HeaderComponent,
        FooterComponent,
        TeamMainComponent,
        BannerComponent,
        ArticleListComponent
    ],
    providers: [
        NotificationsHelper,
        NetworkHelper,
        HttpClient,
        PopupHelper
    ]
})
export class LandingComponentsModule { }
