<div *ngIf="type === types.TEXT_INPUT
|| type === types.PHONE_INPUT
|| type === types.EMAIL_INPUT
|| type === types.NUMBER_INPUT
|| type === types.PASSWORD_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <input [type]="type"
           [name]="name"
           [id]="id"
           [ngClass]="disabled?'disabled' : ''"
           [(ngModel)]="itemModel.value"
           [disabled]="disabled" class="form-control"/>
</div>

<div *ngIf="type === types.TEXTAREA_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <textarea [name]="name"
              [id]="id"
              [ngClass]="disabled?'disabled' : ''"
              [(ngModel)]="itemModel.value"
              [disabled]="disabled"
              class="form-control">
                    </textarea>
    <!--    <textarea [name]="name"-->
    <!--              [id] = "id"-->
    <!--              [ngClass]="disabled?'disabled' : ''"-->
    <!--              [(ngModel)]="itemModel.value"-->
    <!--              [disabled]="disabled"-->
    <!--              class="form-control" >-->
    <!--                    </textarea>-->
</div>
<div *ngIf="type === types.SELECT_BOX" class="form-group" [ngClass]="styles">
    <span class="col-sm-12 col-form-label">{{label}}</span>
    <ng-select class="'ng-select mr-11'"
               [id]="id"
               [ngClass]="disabled?'disabled' : ''"
               [(ngModel)]="itemModel.value"
               [disabled]="disabled"
               [options]="selectBoxOptions"></ng-select>
</div>

<div *ngIf="type === types.MULTISELECT_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <ng-select class="'ng-select mr-11'"
               [id]="id"
               [multiple]="true"
               [ngClass]="disabled?'disabled' : ''"
               [(ngModel)]="itemModel.value"
               [disabled]="disabled"
               [options]="selectBoxOptions"></ng-select>
</div>


<div *ngIf="type === types.CHECKBOX" class="form-group" [ngClass]="styles">
    <input type="checkbox" [id]="id"
           [name]="name"
           [(ngModel)]="itemModel.value"
           [disabled]="disabled"
    >
    <label [for]="id" class="cr">{{label}}</label>
</div>


<div *ngIf="type === types.REACH_TEXT_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id" class="cr">{{label}}</label>
    <app-tinymce [(ngModel)]='itemModel.value'
                 [id]="id"
                 [options]="{force_hex_style_colors: true}"
                 [disabled]="disabled"
    ></app-tinymce>
</div>

<div *ngIf="type === types.TIME_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <input [type]="type" [name]="name"
           [id]="id"
           [ngClass]="disabled?'disabled' : ''"
           [(ngModel)]="itemModel.value"
           [disabled]="disabled"
           atp-time-picker
           class="form-control"/>
</div>

<div *ngIf="type === types.DATE_INPUT" class="form-group" [ngClass]="styles">
    <div class="input-group">
        <input class="form-control"
               autocomplete="off"
               ngbDatepicker
               #d="ngbDatepicker"
               [type]="type" [name]="name"
               [id]="id"
               [ngClass]="disabled?'disabled' : ''"
               [(ngModel)]="itemModel.value"
               [disabled]="disabled"
        />
        <div class="input-group-append">
          <span class="input-group-text" (click)="d.toggle()">
            <i class="fa fa-calendar"></i>
          </span>
        </div>
    </div>
</div>


<div *ngIf="type === types.DATE_INPUT" class="form-group" [ngClass]="styles">
    <div class="input-group">
        <input class="form-control"
               autocomplete="off"
               ngbDatepicker
               #d="ngbDatepicker"
               [type]="type" [name]="name"
               [id]="id"
               [ngClass]="disabled?'disabled' : ''"
               [(ngModel)]="itemModel.value"
               [disabled]="disabled"
        />
        <div class="input-group-append">
          <span class="input-group-text" (click)="d.toggle()">
            <i class="fa fa-calendar"></i>
          </span>
        </div>
    </div>
</div>


<div *ngIf="type === types.CHOOSE_FILE" class="form-group">
    <div class="file-simulation-div">
        <h3>{{label}}</h3>
    
    </div>
</div>

<div *ngIf="type === types.CHOOSE_FILES" class="form-group" [ngClass]="styles">
    <div class="file-simulation-div">
        <h3>{{label}}</h3>
        <app-user-images (getImages)="callToParent($event)">
            <button class="app-user-images-show-button">
                <span class="file-simulation" [ngClass]="styles"
                      *ngIf="!itemModel._id">CLICK FOR SIMULATE ADD IMAGE</span>
                <img [src]="itemModel.s3Url" *ngIf="itemModel._id" [ngClass]="styles" class="image"
                     [alt]="itemModel.title"/>
            </button>
        </app-user-images>
    </div>
</div>
<div class="form-group" [ngClass]="styles"  *ngIf="type === types.COLOR_INPUT">
    <label [for]="id">{{label}}</label>
    <br>
    <input [name]="name"
           [id]="id"
           [style.background]="itemModel"
           [cpPresetColors]="cpPresetColors"
           [(colorPicker)]="itemModel"
           [(ngModel)]="itemModel"
           (colorPickerChange) = "change($event)"
    />
    <span class="form-bar"></span>
</div>