
<ul class="pagination ng-star-inserted" *ngIf="totalPages>1">
    <li class="page-item disabled" *ngIf="currentPage==1">
        <a class="page-link" style="cursor: pointer">«</a>
    </li>
    <li class="page-item" *ngIf="currentPage!=1">
        <a  class="page-link" (click)="pageClick(currentPage - 1)" style="cursor: pointer">«</a>
    </li>
    <li [class]="'page-item ng-star-inserted '" [style]="page.classAproove" *ngFor="let page of pages">
        <a class="page-link" *ngIf="page.name!=='...'" [style]="page.classAproove + ' cursor: pointer'" (click)="pageClick(page.name)">{{page.name}}</a>
        <a class="page-link" *ngIf="page.name==='...'"  style="cursor: pointer">{{page.name}}</a>
    </li>
    <li *ngIf="currentPage==totalPages" class="page-item disabled">
        <a class="page-link" style="cursor: pointer">»</a>
    </li>
    <li *ngIf="currentPage!=totalPages" class="page-item">
        <a class="page-link" style="cursor: pointer"  (click)="pageClick(currentPage + 1)">»</a>
    </li>
</ul>
