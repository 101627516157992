<div id="businessMain">
  <div class="row">
    <div
            *ngFor="let item of CARD; index as i"
            class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6"
            [ngClass]="item.category == index? 'd-block':'d-none' ">
      <div   class="card">
        <h1>{{item.title}}</h1>
        <p class="date">
          <img src="../../../../assets/images/img/TimeCircle.png" alt="">
          Last update{{item.data}}&nbsp;&nbsp;<i>{{item.card}}</i>
        </p>
        <p class="text">{{item.text}}</p>
        <a>
          more details &ensp; &ensp; &nbsp;&nbsp;
          <i class="fa fa-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</div>

