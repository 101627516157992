<a class="text-reset me-3" href="#" (click)="openNav()">
  <img src="assets/images/nav/Filter.svg" alt="Filter">
</a>
<div id="myNav" class="overlay" [ngClass]="bool? 'open' : 'closed'">
  <div class="container">
    <header class="row">
      <div class="col-6">
        <a class="navbar-brand mt-2 mt-lg-0" href="#">
          <img src="assets/images/nav/Logo.svg" alt="">
        </a>
      </div>
      <div class="col-6">
        <div class="left">
          <a class="closebtn" (click)="openNav()">&times;</a>
        </div>
      </div>
    </header>
  </div>
  <div class="container-fluid">
    <section class="section-content ">
      <div class="row">
        <aside class="col-lg-4 card">
          <!-- ============= COMPONENT ============== -->
          <nav class="sidebar   py-2 mb-4">
            <ul class="nav flex-column">

              <li
                      class="nav-item"
                      *ngFor="let nav of navElements;"
                      (click)="openDrop(dropMenu, nav,text)">
                <a class="nav-link" href="#"> {{nav}}<b class="float-end"> </b> </a>

              </li>
            </ul>


          </nav>
          <div class="contact">
            <ul class="nav flex-column">
              <li class="nav-item">
                <img src="assets/images/nav/Call.png" alt="">
                <a href="">&nbsp;&nbsp;( 374) 60 38 88 88</a>
              </li>
              <li class="nav-item">
                <img src="assets/images/nav/email%20(2)%201.png" alt="">
                <a href="">&nbsp;&nbsp;mellat@mellatbank.am</a>
              </li>
              <li class="nav-item">
                <a href=""><img alt="" src="assets/images/footerIcon/Group%206.svg"></a>
                <a href=""><img alt="" src="assets/images/footerIcon/Group%205.svg"></a>
                <a href=""><img alt="" src="assets/images/footerIcon/Group%204.svg"></a>
              </li>
            </ul>
          </div>
          <!-- ============= COMPONENT END// ============== -->
        </aside>

        <div class=" dropmenu col-lg-3 card" #dropMenu>
          <ul class="submenu" *ngIf="elem == 'Individuals'">
            <li *ngFor="let element of elemdrop.Individuals">
              <a class="nav-link" href="#" (click)="showText(element, text)">{{element}}</a>
            </li>
          </ul>
          <ul class="submenu " *ngIf="elem == 'Businesses'">
            <li *ngFor="let element of elemdrop.Businesses">
              <a class="nav-link" href="#" (click)="showText(element, text)">{{element}}</a>
            </li>
          </ul>
          <ul class="submenu" *ngIf="elem == 'Calculators'">
            <li *ngFor="let element of elemdrop.Calculators">
              <a class="nav-link" href="#" (click)="showText(element, text)">{{element}}</a>
            </li>
          </ul>
          <ul class="submenu" *ngIf="elem == 'Tariffs'">
            <li *ngFor="let element of elemdrop.Tariffs">
              <a class="nav-link" href="#" (click)="showText(element, text)">{{element}}</a>
            </li>
          </ul>
          <ul class="submenu" *ngIf="elem == 'About Mellat'">
            <li *ngFor="let element of elemdrop['About Mellat']">
              <a class="nav-link" href="#" (click)="showText(element, text)">{{element}}</a>
            </li>
          </ul>
        </div>
        <div class="col-5 text " #text>
          <div class="container">
            <div class="row">
              <div class="col-10">
                <h1>{{dropElem}}</h1>
                <h3>{{dropElem}}{{dropElemText}}{{dropElem}}</h3>
              </div>

            </div>

          </div>

        </div>
        <main class="col-lg-8 default" *ngIf="!drop">
          <div class="container ">
            <div class="row">
              <div class="col-6">
                <div class="cards">
                  <img src="assets/images/img/image%2018%20.png" alt="">
                  <div class="">
                    <h2 class="">Recent Achievements</h2>
                    <p>To ensure sustainability of its activities, Mellat Bank CJSC has set up a backup space and
                      information system</p>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="cards">
                  <img src="assets/images/img/image%201.png" alt="">
                  <div class="">
                    <h2 class="">Recent Achievements</h2>
                    <p>To ensure sustainability of its activities, Mellat Bank CJSC has set up a backup space and
                      information system</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
    </section>

  </div>
</div>
