<ng-template #content role="document" let-modal>

  <div class="modal-wrapper  {{class}} ">
    <div class="modal-header">
      <ng-content select=".app-modal-header"></ng-content>
    </div>
    <div class="modal-body">
      <ng-content select=".app-modal-body"></ng-content>
    </div>
    <div class="modal-footer">
      <ng-content select=".app-modal-footer"></ng-content>
    </div>
  </div>
</ng-template>


