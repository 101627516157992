<div class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-12	col-sm-5 col-xl-5 title">
        <h1>The most popular Mellat services</h1>
      </div>
      <div class="col-lg-7 col-md-7 col-12	col-sm-7 col-xl-7">
        <ul >
          <li *ngFor="let question of questions; index as i" (click)="open(text, fa)">
            <h2>{{question}}</h2>
            <i class="fa fa-angle-right" #fa></i>
            <div class="text" #text >
              <h3>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta excepturi modi
                numquam quam sed sequi tempora! A aspernatur beatae, consectetur dolorem doloremque ducimus eius eligendi
                et, iusto laborum laudantium nesciunt numquam officia perferendis perspiciatis porro quae rem tempore veritatis,
                vitae. Aliquam eos et in necessitatibus quisquam. Aliquid aspernatur doloribus eaque eligendi, eos esse
                ipsa laborum nesciunt nulla optio perspiciatis quo quod rerum sint tempore temporibus ullam voluptatibus.
                Maxime odio perferendis sed? Ab accusamus atque consequatur cumque dolore ea esse excepturi fuga, fugiat
                ipsam labore maxime modi, necessitatibus nihil nisi non officia omnis quibusdam reprehenderit similique
                vel veritatis voluptas voluptates voluptatibus.
              </h3>

            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>

