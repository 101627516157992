<div *ngIf="type === types.TEXT_INPUT
|| type === types.PHONE_INPUT
|| type === types.EMAIL_INPUT
|| type === types.NUMBER_INPUT
|| type === types.PASSWORD_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <input [type]="type" [name]="name"
           [id]="id"
           [ngClass]="disabled?'disabled' : ''"
           [formControl]="formControlItem"
           [disabled]="disabled"
           class="form-control"/>
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>
<div class="form-group" [ngClass]="styles"  *ngIf="type === types.COLOR_INPUT">
    <label [for]="id">{{label}}</label>
    <br>
    <input [name]="name"
           [id]="id"
           [style.background]="formControlItem.value"
           [cpPresetColors]="cpPresetColors"
           [(colorPicker)]="formControlItem.value"
    />
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>

<div *ngIf="type === types.TEXTAREA_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <textarea [name]="name"
              [id]="id"
              [ngClass]="disabled?'disabled' : ''"
              [formControl]="formControlItem"
              [disabled]="disabled"
              class="form-control">
    </textarea>
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>


<div *ngIf="type === types.SELECT_BOX" class="form-group" [ngClass]="styles">
    <span class="col-sm-12 col-form-label">{{label}}</span>
    <ng-select class="'ng-select mr-11'"
               [id]="id"
               [ngClass]="disabled?'disabled' : ''"
               [formControl]="formControlItem"
               [disabled]="disabled"
               [options]="selectBoxOptions"></ng-select>
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>

<div *ngIf="type === types.MULTISELECT_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <ng-select class="'ng-select mr-11'"
               [id]="id"
               [multiple]="true"
               [ngClass]="disabled?'disabled' : ''"
               [formControl]="formControlItem"
               [disabled]="disabled"
               [options]="selectBoxOptions"></ng-select>
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>


<div *ngIf="type === types.CHECKBOX" class="form-group" [ngClass]="styles">
    <input type="checkbox" [formControl]="formControlItem" [id]="id"
           [name]="name"
           [ngClass]="disabled?'disabled' : ''"
           [disabled]="disabled"
    >
    <label [for]="id" class="cr">{{label}}</label>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>


<div *ngIf="type === types.REACH_TEXT_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id" class="cr">{{label}}</label>
    <app-tinymce [(ngModel)]='formControlItem'
                 [id]="id"
                 [disabled]="disabled"
    ></app-tinymce>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>

<div *ngIf="type === types.TIME_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <input [type]="type" [name]="name"
           [id]="id"
           [ngClass]="disabled?'disabled' : ''"
           [formControl]="formControlItem"
           [disabled]="disabled"
           atp-time-picker
           class="form-control"/>
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>

<div *ngIf="type === types.DATE_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <div class="input-group">
        <input class="form-control"
               autocomplete="off"
               ngbDatepicker
               #d="ngbDatepicker"
               [type]="'text'" [name]="name"
               [id]="id"
               [ngClass]="disabled?'disabled' : ''"
               [formControl]="formControlItem.value"
               [required]="required"
               [disabled]="disabled"
        />
        <div class="input-group-append">
          <span class="input-group-text" (click)="d.toggle()">
            <i class="fa fa-calendar"></i>
          </span>
        </div>
    </div>
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>


<div *ngIf="type === types.DATE_RANGE_INPUT" class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <div class="input-group">
        <input class="form-control"
               ngbDatepicker
               #d="ngbDatepicker"
               [required]="required"
               [type]="type"
               [name]="name"
               [id]="id"
               [ngClass]="disabled?'disabled' : ''"
               [formControl]="formControlItem"
               [disabled]="disabled"
        />
        <div class="input-group-append">
          <span class="input-group-text" (click)="d.toggle()">
            <i class="fa fa-calendar"></i>
          </span>
        </div>
    </div>
    <span class="form-bar"></span>
    <app-validation-error
            [item]="formControlItem" [customMessage]="errorMessage"></app-validation-error>
</div>


<div class="col-md-12" *ngIf="type === types.CHOOSE_FILE">
    <div class="form-group mt-2">
        <label>{{label?label:'Banner Image'}}</label>
        <app-file-uploader (imageSrc)="setLogo($event)" [showRemove]="showRemove" [initialImage]="logoObject"
                           [defaultImage]="logoObject"></app-file-uploader>
        <a class="remove-image-button" *ngIf="logoObject" (click)="deleteLogo()"><i class="fa fa-times"></i></a>
    </div>
</div>

