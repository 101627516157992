'use strict';

//For user
const UserRole = {
    DEVELOPER: {value:'developer', display: 'Developer'},
    ADMIN: {value:'admin', display: 'Admin'},
    LOAN_MANAGER: {value:'loan-manager', display: 'Loan manager'},
    SERVICE_MANAGER: {value:'service-manager', display: 'Service Manager'},
    CONTENT_WRITER: {value:'content-manager', display: 'Content Manager'},
    MARKETING: {value:'marketing', display: 'Marketing'},
    SUPERADMIN: {value:'superAdmin', display: 'Super Admin'},
};
const contentType = {
    SINGLE: {value:'single', display: 'Single'},
    MULTI: {value:'multi', display: 'Multi'}
}

const categories = {
    BUSINESS: {value:'business', display: 'Business'},
    REPORTS: {value:'reports', display: 'Reports'},
    FINANCE: {value:'finance', display: 'Finance'},
    FOR_BUSINESS: {value:'for_business', display: 'For Business'}
}
const categoriesLoan = {
    LOAN1: {value:'Loan1', display: 'Mortgage Loans'},
    LOAN2: {value:'Loan2', display: 'Consumer Loans'},
    LOAN3: {value:'Loan3', display: 'Express Consumer Loans'},
    LOAN4: {value:'Loan4', display: 'Car Loans'},
    LOAN5: {value:'Loan5', display: 'Student Loans'},
    LOAN6: {value:'Loan6', display: 'Business Loans'},
    LOAN7: {value:'Loan7', display: 'Express Business Loans'}
}

const UserType = {
    API: {value:'api', display: 'api'},
    USER: {value:'user', display: 'user'}
};

const LoanType = {
    DIFFERENTIAL: {value:'differential', display: 'DIFFERENTIAL'},
    ANNUITY: {value:'annuity', display: 'ANNUITY'},
}
const LoanStatus = {
    ACTIVE: {value:'active', display: 'ACTIVE'},
    DEACTIVATED: {value:'deactivated', display: 'DEACTIVATED'},
    DELETED: {value:'deleted', display: 'DELETED'},
}

const SessionType = {
    API: {value:'api', display: 'api'},
    USER: {value:'user', display: 'user'}
};
const UserStatus = {
    NEW: {value:'new', display: 'New'},
    ACTIVE: {value:'active', display: 'Active'},
    FULLY_VERIFIED: {value:'fullyVerified', display: 'Fully verified'},
    INACTIVE: {value:'inactive', display: 'Inactive'},
    DELETED: {value:'deleted', display: 'Deleted'},
};
const Languages = {
    ARMENIAN : {value:'am', display: 'am'} ,
    ENGLISH : {value:'en', display: 'en'} ,
    RUSSIAN : {value:'ru', display: 'ru'} ,
    GEORGIAN : {value:'ka', display: 'ka'} ,
    UKRAINE : {value:'ua', display: 'ua'}
};
const Currencies = {
    USD:  {value:'USD', display: 'USD'},
    AMD:  {value:'AMD', display: 'AMD'},
    RUB:  {value:'RUB', display: 'RUB'},
    EUR:  {value:'EUR', display: 'EUR'},
    GEL:  {value:'GEL', display: 'GEL'},
    UAH:  {value:'UAH', display: 'UAH'}
};
//reset password statuses
const ResetPasswordStatuses = {
    ACTIVE: {value:'active', display: 'active'},
    USED: {value:'used', display: 'used'},
    EXPIRED: {value:'expired', display: 'expired'}
};
const sliderTypes = {
    HOME: {value:'home_slider', display: 'Home slider'},
    SERVICE: {value:'service', display: 'Banners section'},
    LOAN: {value:'loan_slider', display: 'Loan slider'},
    DEFAULT: {value:'default', display: 'default'},
}
const TabsTypes = {
    vertical: {value:'vertical', display: 'Vertical'},
    horizontal: {value:'horizontal', display: 'Horizontal'}
}
const TabStatus = {
    ACTIVE: {value:'active', display: 'Active'},
    DELETED: {value:'deleted', display: 'Deleted'}
}
//Article
const ArticleStatus = {
    PENDING: {value:'pending', display: 'pending'},
    ACTIVE: {value:'active', display: 'active'},
    DELETED: {value:'deleted', display: 'deleted'}
};
//Category
const CategoryStatus = {
    ACTIVE: {value:'active', display: 'active'},
    DELETED: {value:'deleted', display: 'deleted'}
};

//Subscriber
const SubscriberStatus = {
    NEW: {value:'new', display: 'NEW'},
    ACTIVE: {value:'active', display: 'Active'},
    DELETED: {value:'deleted', display: 'Deleted'},
    UNSUBSCRIBE: {value:'unsubscribe', display: 'Unsubscribe'}
};
const SubscriberTables = {
    ALL: {value:'all', display: 'All'},
    SOCIAL_PAGE: {value:'deleted', display: 'Deleted'},
    EVENT: {value:'event', display: 'Event'},
    CHALLENGE: {value:'challenge', display: 'Challenge'},
    TOURNAMENT: {value:'tournament', display: 'Tournament'}
};
//Attachments
const AttachmentType = {
    IMAGE: {value:'image', display: 'Image'},
    VIDEO: {value:'video', display: 'Video'},
    DOCUMENT: {value:'document', display: 'File'}
};
const MediaName = {
    AVATAR: {value:'Avatar', display: 'Avatar'},
    POST: {value:'Post', display: 'Post'},
    VIDEO: {value:'PostVideo', display: 'Video'}
}
const ApplicationTypes = {
    WEB: {value:'web', display: 'Web'},
    MOBILE: {value:'mobile', display: 'Mobile'}
}
const FormStatus = {
    ACTIVE: {value:'active', display: 'active'},
    DELETED: {value:'deleted', display: 'deleted'}
}

const FormType = {
    SUBSCRIBE: {value:'subscribe', display: 'subscribe'},
    CONTACT_US: {value:'contactUs', display: 'contact Us'}
}

const InputType = {
    TEXT: {value:'text', display: 'text'},
    TEXTAREA: {value:'textarea', display: 'textarea'},
    CHECKBOX: {value:'checkbox', display: 'checkbox'},
    EMAIL: {value:'email', display: 'email'}
}

const LocalizationStatus = {
    ACTIVE: {value:'active', display: 'Active'},
    INACTIVE: {value:'inactive', display: 'Inactive'}
}

const BannerStatus = {
    ACTIVE: {value:'active', display: 'Active'},
    DELETED: {value:'deleted', display: 'deleted'}
}


const SliderStatus = {
    ACTIVE: {value:'active', display: 'active'},
    DELETED: {value:'deleted', display: 'deleted'}
}

const BannerType = {
    bannerFullWidth: {value:'bannerFullWidth', display: 'Banner full width'},
    about_us: {value:'aboutUs', display: 'About us'},
    default: {value:'default', display: 'default'},
    banners_business_and_individual: {value:'bannerInWebSeo', display: 'Banner business and individual'},
    bannerStructure: {value:'bannerStructure', display: 'banner Structure'},
    bannerHomePage: {value:'bannerHomePage', display: 'Banner home page'},
    bannerWithBreadcrumbs: {value:'banner_with_breadcrumbs', display: 'Banner with breadcrumbs'},

}
const TabsType = {
    tabs_left_side: {value:'standard', display: 'standard'},
    tabs_business_and_individual: {value:'home_slider', display: 'home_slider'}
}

const SectionType = {
    tab:  {value:'tab', display: 'Tab'}, //tabs
    caption:  {value:'caption', display: 'Caption'},//nav_slug_widget
    reachEditor:  {value:'reachEditor', display: 'Reach Editor'},//nav_slug_widget
    banner: {value:'banner', display: 'Banner'}, //banner
    aboutUs:  {value:'aboutUs', display: 'About Us'}, //about_us
    questionsAndAnswers: {value:'questions-and-answers', display: 'Questions and answers'}, //question_and_answers, popular_services_qa
    rates:  {value:'rates', display: 'Rates'}, //rate_calculator
    deposit_calculator:  {value:'deposit_calculator', display: 'Deposit Calculator'}, //deposit_calculator
    latestNews:  {value:'latestNews', display: 'Latest News'},
    articlesList:  {value:'articleList', display: 'Article List'},
    servicesListBusiness:  {value:'serviceListBusiness', display: 'Service List Business'},
    servicesListIndividual:  {value:'serviceListIndividual', display: 'Service List Individual'},
    loanListIndividual:  {value:'loanListIndividual', display: 'Loan List Individual'},
    loanListBusiness:  {value:'loanListBusiness', display: 'Loan List Business'},
    mostPopularServices:  {value:'questions-and-answers', display: 'MostPopularServices'},
    slider:  {value:'carusel', display: 'Slider'},
    loanCalculator:  {value:'loanCalculator', display: 'LoanCalculator'}, //loan_calculator
    contactUs:  {value:'contactUs', display: 'ContactUs'}, //contact_us_form
    applyLoanForm:  {value:'applyLoanForm', display: 'Apply Loan Form'}, //apply_for_loan_form
    teamMembers:  {value:'team', display: 'Team Members'}, // team
    filesPackage:  {value:'package', display: 'Files Package'}, //files-package
    fullWidthImage:  {value:'fullWidthImage', display: 'Full Width Image'}, // fullWidth image

}

const PageType = {
    page:  {value:'page', display: 'Page'},
    article:  {value:'article', display: 'Article'},
    serviceIndividual:  {value:'serviceIndividual', display: 'Service Individual'},
    serviceBusiness:  {value:'serviceBusiness', display: 'Service Business'},
    loanIndividual:  {value:'loanIndividual', display: 'Loan Individual'},
    loanBusiness:  {value:'loanBusiness', display: 'Loan Business'},
}

const CategoryPosition = {
    MENU: {value:'menu', display: 'Menu'},
    // FOOTER_LEFT: {value:'footerLeft', display: 'Footer Left'},
    // FOOTER_RIGHT: {value:'footerRight', display: 'Footer Right'},
    FOOTER_CENTER: {value:'footerCenter', display: 'Footer'},
}

const PageStatus = {
    ACTIVE: {value:'active', display: 'active'},
    ARCHIVE: {value:'archive', display: 'archive'},
    DELETED: {value:'deleted', display: 'deleted'}
}
const LayoutTypes = {
    LEFT_MIDDLE: {value:'left-middle', display: 'Left, Middle'},
    LEFT_MIDDLE_RIGHT: {value:'left-middle-right', display: 'Left, Middle, Right'},
    MIDDLE_RIGHT: {value:'middle-right', display: 'Middle, Right'},
    MIDDLE: {value:'middle', display: 'Only one section'},
    LEFT_RIGHT: {value:'left-right', display: 'Two Columns'}
}

const QuestionStatuses = {
    ACTIVE: {value:'active', display: 'active'},
    DELETED: {value:'deleted', display: 'deleted'}
}


const   ImageSizes =[
    {
        id:1,
        title:"Square",
        width:"100",
        smWidth:"50",
        class:"size-1-1",
        cropperSettings:{
            resizeToWidth: 1024,
            resizeToHeight: 1024,
            ratio: 1,
            roundCropper: false
        }
    },
    {
        id:2,
        title:"Game banner 3X1",
        width:"70",
        smWidth:"30",
        class:"size-3-1",
        cropperSettings:{
            resizeToWidth: 1526,
            resizeToHeight: 512,
            ratio: 3,
            roundCropper: false
        }
    },
    {
        id:3,
        title:"1440 X 660",
        width:"150",
        smWidth:"90",
        class:"size-2-1",
        cropperSettings:{
            resizeToWidth: 1440,
            resizeToHeight: 660,
            ratio: 1440 / 660,
            roundCropper: false
        }
    },
    {
        id:4,
        title:"meta image",
        width:"150",
        smWidth:"90",
        class:"size-2-1",
        cropperSettings:{
            resizeToWidth: 1200,
            resizeToHeight: 630,
            ratio: 1200 / 630,
            roundCropper: false
        }
    },

    {
        id:5,
        title:"gallery image",
        width:"150",
        smWidth:"90",
        class:"size-2-1",
        cropperSettings:{
            resizeToWidth: 1200,
            resizeToHeight: 630,
            ratio: 1200 / 630,
            roundCropper: false
        }
    },

]
const teamMemberTypes = {
    MAIN: {value:'mainPositions', display: 'Main'},
    SECOND: {value:'secondaryPositions', display: 'Second'},
    THIRD: {value:'thirdPositions', display: 'Third'},
}
const teamStatus = {
    ACTIVE: {value:'active', display: 'active'},
    DELETED: {value:'deleted', display: 'deleted'}
}

const SliderType =  {
    home_slider: {value:'home_slider', display: 'home_slider'},
    service: {value:'service', display: 'home_slider'},
    loan_slider: {value:'loan_slider', display: 'loan_slider'}
}


const CaruselStatus = {
    ACTIVE: {value:'active', display: 'Active'},
    DELETED: {value:'deleted', display: 'Delete'}
}
module.exports = Object.freeze({
    UserRole,
    UserStatus,
    Currencies,
    Languages,
    ResetPasswordStatuses,
    ArticleStatus,
    SubscriberStatus,
    SessionType,
    CategoryStatus,
    SubscriberTables,
    UserType,
    AttachmentType,
    ApplicationTypes,
    MediaName,
    FormStatus,
    FormType,
    LocalizationStatus,
    InputType,
    BannerStatus,
    BannerType,
    SliderStatus,
    SliderType,
    SectionType,
    PageType,
    PageStatus,
    LayoutTypes,
    CategoryPosition,
    LoanType,
    LoanStatus,
    QuestionStatuses,
    ImageSizes,
    TabsTypes,
    TabStatus,
    contentType,
    teamMemberTypes,
    teamStatus,
    categories,
    categoriesLoan,
    sliderTypes,
    CaruselStatus,
    TabsType
});
