import { Component, OnInit,Input } from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";

@Component({
  selector: 'app-loan-calc',
  templateUrl: './loan-calc.component.html',
  styleUrls: ['./loan-calc.component.scss']
})
export class LoanCalcComponent implements OnInit {

 bool = false;
  calcTable: Array<{
    month?: number;
    interest?: number;
    loan?: number;
    payment?: number;

  }> | undefined;
  // calctable = [{}]
  objectKeys = Object.keys;
  calcForm: FormGroup | {
    rate: number  ;
    loanAmount: number;
    term: number;
    inlineRadioOptions: number;
  } | undefined;
  public amount: number | undefined;
  // @ts-ignore
  public rate: number;
  // @ts-ignore
  public term: number;
  public total: number | undefined;
  constructor() { }

  ngOnInit(): void {

  }
  onSubmit(form: NgForm) {
    this.bool=false;
    this.calcTable = []
    this.calcForm = new FormGroup({
      loanAmount: new FormControl(form.form.value.loanAmount, [Validators.required]),
      term: new FormControl(form.form.value.term, [Validators.required]),
      rate: new FormControl(form.form.value.rate, [Validators.required]) ,
      inlineRadioOptions: new FormControl(form.form.value.inlineRadioOptions, [Validators.required])
    });

    if (this.calcForm.status === "VALID"){
      this.amount = this.calcForm.value.loanAmount
      this.rate = this.calcForm.value.rate
      this.term = this.calcForm.value.term
      this.total = ((this.calcForm.value.loanAmount/this.calcForm.value.term + (this.calcForm.value.loanAmount * this.calcForm.value.rate)/(this.calcForm.value.term*100))*this.calcForm.value.term)
      this.bool=true;

      for (let i = 1 ; i <= this.calcForm.value.term; i++){
        this.calcTable.push({
          month: i,
          interest: ((this.calcForm.value.loanAmount*this.calcForm.value.rate)/1200),
          loan: (this.calcForm.value.loanAmount / this.calcForm.value.term) ,
          payment: this.calcForm.value.loanAmount *(this.calcForm.value.rate + (this.calcForm.value.rate/(Math.pow(1+this.calcForm.value.rate,this.calcForm.value.term)-1))) ,
          // payment: (this.calcForm.value.loanAmount/this.calcForm.value.term + (this.calcForm.value.loanAmount * this.calcForm.value.rate)/(this.calcForm.value.term*100)).toFixed(2)
        })

      }
    }
  }
}
