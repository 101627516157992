import {Component, Input, OnInit} from '@angular/core';
interface card {
  title: string;
  data: string;
  card :string;
  text: string;
  category: string;
}
@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {
  @Input() cards:any;
  CARD = [
    {
      title: 'Loans',
      data: '2022-01-18',
      card : 'Loans',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly scalable test ' +
        'infrastructure to ensure quality? If you love paying attention to detail and the startup environment, you\'ll love this position.',
      category: 'individual',
    },
    {
      title: 'Accounting',
      data: '2022-02-20',
      card : 'Loans',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a' +
        ' truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the' +
        ' startup environment, you\'ll love this position.',
      category: 'individual',
    },
    {
      title: 'Escrow',
      data: '2022-02-10',
      card : 'Loans',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly' +
        ' scalable test infrastructure to ensure quality? If you love paying attention to detail and the startup' +
        ' environment, you\'ll love this position.',
      category: 'individual',
    },
    {
      title: 'Transfers',
      data: '2022-01-18',
      card : 'Transfers',
      text: 'We seek excellence in each digital product we craft. Would you like to help our team create a ' +
        'truly scalable test infrastructure to ensure quality? If you love paying attention to detail and' +
        ' the startup environment, you\'ll love this position.',
      category: 'business',
    },
    {
      title: 'Investment',
      data: '2022-02-20',
      card : 'Transfers',
      text: 'We seek excellence in each digital product we craft. Would you like to help our' +
        ' team create a truly scalable test infrastructure to ensure quality? If you love paying ' +
        'attention to detail and the startup environment, you\'ll love this position.',
      category: 'business',
    }

  ];
  index = ''

  @Input() message: any;
  constructor() { }

  ngOnInit(): void {
    this.CARD = [];
    this.cards = this.cards.docs;
    for(let key in this.cards){
      this.CARD.push({
        title: this.cards[key].title[0].content,
        data: this.cards[key].updated_at,
        card :  this.cards[key].title[0].content,
        text: this.cards[key].short_description[0].content,
        category: this.cards[key].as_article_category[0],
      })
    }
    //this.index = this.message[0];
  }


  get(index: string) {
    this.index = index
  }
}
