import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ImageItemModel} from "../../../../core/model/image-item.model";




@Component({
  selector: 'app-mosaic-images',
  templateUrl: './mosaic-images.component.html',
  styleUrls: ['./mosaic-images.component.scss']
})
export class MosaicImagesComponent implements OnInit {

  @Input() data: Array<ImageItemModel> =[];
  @Input() selectedItem: ImageItemModel;
  @Input() next: string;
  @Output() edit = new EventEmitter();
  @Output() loadMore = new EventEmitter();

  selectedImages: Array<ImageItemModel> =[];
  selectedItemsIndexArr: Array<string> =[];
  selectedItemIndex: string;
  hasError = false;

  constructor() { }


  ngOnInit(): void {

  }


  selectItem(index:string,item: ImageItemModel):void{
    const indexOfArr = this.selectedItemsIndexArr.indexOf(index);
    if (indexOfArr > -1) {
      this.selectedItemsIndexArr.splice(indexOfArr, 1);
      this.selectedImages.splice(indexOfArr, 1);
    }else{
      this.selectedItemsIndexArr.push(index)
      this.selectedImages.push(item)
    }
  }

  editDelete(index:string, data:ImageItemModel):void{
    this.edit.emit(data.id)
    this.selectedItemIndex =index
  }

  onLoadMore():void{
    this.loadMore.emit()
  }

}
