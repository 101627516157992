<div id="tab">
    <div class="s2">
        <h3>{{package.name}}</h3>
        <ul class="download">
            <li *ngFor="let file of files">
                <img src="assets/images/img/Downlaod.png" alt="">
                <a href="{{mainPath + '/' + file.path}}" download>{{file.name}}</a>
            </li>
        </ul>
    </div>
</div>
