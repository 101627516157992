import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-article-list',
    templateUrl: './article-list.component.html',
    styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {
    @Input() article: any;
    public articleList
    filePath = environment.filePath
    tabsArticle = [
        {name: {en:'Business',am:'Բիզնես'}, art: 'business'},
        {name: {en:'Annual reports',am:'Տարեկան հաշվետվություններ'}, art: 'reports'},
        {name: {en:'Finance',am:'Ֆինանսներ'}, art: 'finance'},
        {name: {en:'For Businesses',am:'Բիզնեսների համար'}, art: 'for_business'},
    ]
    tabs = {
        business: [],
        reports: [],
        finance: [],
        for_business: [],
    }

    public ind: number = 0;
    public lang: string = 'en';

    constructor(public route: ActivatedRoute) {
    }

    ngOnInit(): void {

        this.articleList = this.article.docs
        for (let key in this.articleList) {
            if (this.articleList[key].as_article_category.length > 0) {
                for (let key1 in this.articleList[key].as_article_category) {
                    let index = this.articleList[key].as_article_category[key1]
                    this.tabs[index].push(this.articleList[key])
                }
            }
        }
    }

}
