import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../helpers/network.helper';

@Injectable()
export class AuthService {
    private loginUrl= 'auth/login';
    private logoutUrl = 'auth/logout';
    private registerUrl = 'auth/registration';
    private addPasswordUrl = 'auth/add-password/{{token}}';
    private forgotPasswordUrl = 'auth/forgot-step-1';
    private changePasswordUrl = 'auth/changePassword/{{token}}';

    constructor(private networkHelper: NetworkHelper,
                public http: HttpClient) { }

    login(loginData): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.loginUrl, loginData);
    }

    register(registerData): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.registerUrl, registerData);
    }

    addPassword(token, password): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.addPasswordUrl.replace('{{token}}',token), {password});
    }

    forgotPassword1(forgotData): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.forgotPasswordUrl, forgotData);
    }

    forgotPassword2(token, password): Observable<any> {
        return this.networkHelper.post(environment.apiURL + this.changePasswordUrl.replace('{{token}}',  token), {password});
    }

    logout(): Observable<any> {
        return this.networkHelper.get(environment.apiURL +
            this.logoutUrl);
    }


}
