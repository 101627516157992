import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-pagination-custom',
    templateUrl: './pagination-custom.component.html'
})
export class PaginationCustomComponent implements OnInit, OnChanges {

    @Input() currentPage: number;
    @Input() totalPages: number;
    @Output() pageChange: EventEmitter<any> = new EventEmitter();

    public pages = [];

    constructor() {
    }


    pageClick(pageNumber) {
        this.currentPage = pageNumber;
        this.pageChange.emit(pageNumber);
        this.predifineData();
    }

    predifineData() {
        this.pages = [];
        for (let key = 0; key < this.totalPages; key++) {
            if ( key < 2 || (key > this.currentPage - 3 && key < this.currentPage + 1) || key > this.totalPages - 3 ) {
                if (key + 1 === this.currentPage) {
                    this.pages.push({
                        name: this.currentPage,
                        classAproove: 'color: green'
                    });
                } else {
                    this.pages.push({
                        name: key + 1,
                        classAproove: ''
                    });
                }
            } else if (key === this.currentPage - 3 || key === this.currentPage + 1) {
                this.pages.push({
                    name: '...',
                    classAproove: ''
                });
            }
        }
    }

    ngOnInit() {
        this.predifineData();
    }

    ngOnChanges() {

        this.predifineData();
    }



}
