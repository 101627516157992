
<div *ngIf="type === types.SELECT_BOX"  class="form-group" [ngClass]="styles">
    <span class="col-sm-12 col-form-label">{{label}}</span>
    <ng-select class="'ng-select mr-11'" *ngIf="formControlItem"
               [id] = "id"
               [ngClass]="disabled?'disabled' : ''"
               [formControl]="formControlItem"
               [disabled]="disabled"
               (filterInputChanged)="change($event)"
               [options]="selectBoxOptions" ></ng-select>
    <ng-select class="'ng-select mr-11'" *ngIf="itemModel || useModel"
               [id] = "id"
               [ngClass]="disabled?'disabled' : ''"
               [(ngModel)]="itemModel"
               [disabled]="disabled"
               [name]="name"
               (ngModelChange)="modelChange($event)"
               (filterInputChanged)="change($event)"
               [options]="selectBoxOptions" ></ng-select>
</div>

<div *ngIf="type === types.MULTISELECT_INPUT"  class="form-group" [ngClass]="styles">
    <label [for]="id">{{label}}</label>
    <span class="col-sm-12 col-form-label">{{label}}</span>
    <ng-select class="'ng-select mr-11'"
               [id] = "id"
               [multiple]="true"
               [ngClass]="disabled?'disabled' : ''"
               [(ngModel)]="itemModel.value"
               [disabled]="disabled"
               [options]="selectBoxOptions" ></ng-select>
</div>

