<!-----------------------------------------------------------carousel-------------------------------------------------->
<div *ngIf="type == 'home_slider'" id="carousel" class="carousel">
    <div class="container-md banner-home">
        <div *ngIf="banners">
            <ngb-carousel [showNavigationArrows]="showNavigationArrows"
                          [showNavigationIndicators]="banners.length>1">

                <ng-template *ngFor="let banner of banners" ngbSlide  >
                    <app-banner [package]="banner" [learn] = true ></app-banner>

                </ng-template>

            </ngb-carousel>
        </div>
    </div>
</div>

<!-----------------------------------------------------------carousel carousel_default-------------------------------------------------->
<div *ngIf="type == 'default'" id="carousel_default"  class="carousel">
    <div class="container banner-home">
        <div *ngIf="banners">
            <ngb-carousel [showNavigationArrows]="showNavigationArrows"
                          [showNavigationIndicators]="banners.length>1">

                <ng-template *ngFor="let banner of banners" ngbSlide  >
                    <div class="default_main" >
                        <app-banner [package]="banner" [learn] = true ></app-banner>
                    </div>
                </ng-template>

            </ngb-carousel>
        </div>
    </div>
</div>
<!---------------------------------------------------------offers bus. and ind.---------------------------------------->
<section *ngIf="type == 'service'" class="container" id="offers">
    <div class="title">
        <h1>{{title}}</h1>
    </div>
    <div class="row">
        <div *ngFor="let banner of banners; let i = index" class="col-md-6 col-12	col-sm-12 col-lg-6	col-xl-6">
            <app-banner [package]="banner" [index]="i"></app-banner>
        </div>
    </div>
</section>

<!-----------------------------------------------------------carousel-------------------------------------------------->
<div *ngIf="type == 'loan_slider'" id="loan_carousel">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12" *ngIf="banners">
                <ngb-carousel [showNavigationArrows]="showNavigationArrows"
                              [showNavigationIndicators]="showNavigationIndicators">

                    <ng-template *ngFor="let banner of banners" ngbSlide>
                        <app-banner [package]="banner" ></app-banner>
                    </ng-template>

                </ngb-carousel>
            </div>
        </div>
    </div>
</div>