import {Component, OnInit,} from '@angular/core';

interface Currency {
  flag: string;
  currency: string;
  buy: number;
  sale: number;
}


const CURRENCIES: Currency[] = [
  {
    currency: 'ARM',
    flag: 'image11.png',
    buy: 1,
    sale: 1
  },
  {
    currency: 'USD',
    flag: 'image12.png',
    buy: 480,
    sale: 488
  },
  {
    currency: 'EUR',
    flag: 'image13.png',
    buy: 542,
    sale: 558
  },
  {
    currency: 'RUS',
    flag: 'image14.png',
    buy: 5.9,
    sale: 6.2
  }
];

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  currencies = CURRENCIES;
  public want: number | undefined;
  selectedHaveCurrency = this.currencies[1].currency;
  buy = this.currencies[2].buy;
  selectedWantCurrency = this.currencies[0].currency;
  sale = this.currencies[0].buy;
  public have: number | undefined;
  bool = true;
  selectedHaveCurrencyFlag  = this.currencies[1].flag;
  selectedWantCurrencyFlag  = this.currencies[0].flag;
  dateToday: number = Date.now();

  constructor() {
  }

  ngOnInit(): void {
    let myDate = new Date();
    // console.log(myDate)
  }

  removeHaveCurrent() {
    this.currencies.forEach((currentValue, index) => {
      if (currentValue.currency == this.selectedHaveCurrency) {
        this.buy = currentValue.buy;
      }
    });
  }
  removeWantCurrent() {
    this.currencies.forEach((currentValue, index) => {
      if (currentValue.currency == this.selectedWantCurrency) {
        this.sale = currentValue.buy;
      }
    });
  }

  onKeyUp(event: Event) {

    if ((<HTMLInputElement>event.target).name == 'Have'){
      this.bool = true
      this.have = parseFloat((<HTMLInputElement>event.target).value)
      this.calcWannt()
    }else{
      this.bool = false
      this.want = parseFloat((<HTMLInputElement>event.target).value)
      this.calcHave()
    }

  }
  calcWannt(){
    this.removeHaveCurrent();
    this.removeWantCurrent();
    // @ts-ignore
    this.want = (this.buy * this.have)/this.sale;
    // console.log(this.sale)
  }
  calcHave(){
    this.removeHaveCurrent();
    this.removeWantCurrent();
    // @ts-ignore
    this.have = (this.want * this.sale)/this.buy;
    // console.log(this.sale)
  }

  selectHave(targetName: string, currency: string ){

    if (targetName == 'Have'){
      this.selectedHaveCurrency = currency
      this.currencies.forEach((currentValue, index) => {
        if (currentValue.currency == currency) {
          this.selectedHaveCurrencyFlag  = currentValue.flag;
        }
      });
     this.bool? this.calcWannt():this.calcHave()
    } else if (targetName == 'Want') {
      this.selectedWantCurrency = currency
      this.currencies.forEach((currentValue, index) => {
        if (currentValue.currency == currency) {
          this.selectedWantCurrencyFlag  = currentValue.flag;
        }
      });
      this.bool? this.calcWannt():this.calcHave()
    }

  }
}
