import {Component, Input, OnInit} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {environment} from "../../../../environments/environment";
// import { CarouselModule, WavesModule } from 'angular-bootstrap-md'


@Component({
  selector: 'app-carousel-bar',
  templateUrl: './carousel-bar.component.html',
  styleUrls: ['./carousel-bar.component.scss'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers

})
export class CarouselBarComponent implements OnInit {
  showNavigationArrows = false;
  showNavigationIndicators = true;
  @Input() carusel: any;
  banners = [];
  title = [];
  description = [];
  mainPath = environment.filePath;
  type: string;
  private ind: number=0;

  constructor(config: NgbCarouselConfig) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit(): void {


    this.banners = this.carusel.banners;
    this.title = this.carusel.title[this.ind].content;
    this.description = this.carusel.description[this.ind].content;
    this.type = this.carusel.type

  }


}


