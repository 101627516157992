<div id="contactMain">
  <div class="container ">
    <div class="row">
      <div class="col-12 ">
        <div class="row">
          <div class="col-12	col-sm-12	col-md-12	col-lg-6	col-xl-6 left">
            <h1>Dear Customer</h1>
            <p>Your opinion is important for us, we want to hear back from you.</p>
            <ul>
              <li>
                <img src="../../../../../assets/images/img/Location.png" alt="">
                Charents 19 str.
              </li>
              <li>
                <img src="../../../../../assets/images/img/Call.png" alt="">
                +37460 38 8888
              </li>
              <li>
                <img src="../../../../../assets/images/img/Message.png" alt="">
                infoarm@mellatbank.com
              </li>
            </ul>
          </div>
          <div class="col-12	col-sm-12	col-md-12 col-lg-6	col-xl-6 right">
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Full Name</label>
                <input type="email" class="form-control" id="InputName" aria-describedby="nameHelp" placeholder="What’s your name">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Phone</label>
                <input type="email" class="form-control" id="InputPhone"  placeholder="+">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Subject</label>
                <input type="email" class="form-control" id="InputSubject"  placeholder="Subject">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Message</label>
                <input type="email" class="form-control" id="InputMessage"  placeholder="your Message">
              </div>

              <button type="submit" class="btn btn-primary">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <a href="">Add Map with Mellat Location</a>
  </div>
</div>
