import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {FORM_TYPES} from "../../../../core/formTypes";
import {environment} from "../../../../../environments/environment";
@Component({
    selector: 'app-input-form',
    templateUrl: './input-form.component.html',
    styleUrls: ['./input-form.component.scss'],
})
export class InputFormComponent implements OnInit, OnChanges{
    types = FORM_TYPES;
    @Input() type = FORM_TYPES.TEXT_INPUT;
    @Input() formControlItem;
    @Input() label: any;
    @Input() required = false;
    @Input() placeholder: any
    @Input() styles: any
    @Input() sendToParent: EventEmitter<any>;
    @Input() errorMessage = '';
    @Input() name = '';
    @Input() id = '';
    @Input() disabled = false;
    @Input() selectBoxOptions = [];
    @Input() itemModel;
    @Input() color;
    @Input()cpPresetColors


    showRemove = false;
    changed = false;
    logoObject = '';

    constructor() {
        this.sendToParent = new EventEmitter<any>();
    }

    ngOnInit() {

    }

    setLogo(logo){
        this.logoObject = logo;
        this.formControlItem.patchValue(logo);
        this.showRemove = true;
        this.changed = true;

    }

    deleteLogo(){
        this.logoObject = null;
        this.formControlItem.patchValue('');
        this.changed = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.type === this.types.CHOOSE_FILE && changes.formControlItem.currentValue.value){
            this.logoObject = environment.filePath + changes.formControlItem.currentValue.value;
        }
    }


}
