<!-----------------------------------banner full width------------------->
<section *ngIf="type == 'bannerFullWidth'" [ngStyle]="{'background-color':color}" id="fullBanner">
    <img *ngIf="imgSrc.length>0" alt="first slide" class="d-block img-fluid"
         onerror="this.src='assets/images/img/image%201.png';"
         src="{{imgSrc}}">
    <div *ngIf="description>0" class="carousel-caption  d-md-block">
        <!--        <h2 [ngStyle]="{'color':title_color}">{{title}}</h2>-->
        <!--        <p *ngFor="let text of text"><b>&roarr; &nbsp;&nbsp;</b>{{text}}</p>-->
        <div [innerHTML]="(description)" class=" text"></div>
    </div>
</section>
<!-----------------------about us banner----------------------------->
<div *ngIf="type == 'aboutUs'" [ngStyle]="{'background-color':color}" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12	col-sm-12 col-lg-6	col-xl-6">
                <div class="images">
                    <img *ngIf="imgSrc.length>0" alt="" class="rounded fit-picture"
                         onerror="this.src='assets/images/img/image%201.png';"
                         src="{{imgSrc}}">
                </div>
            </div>
            <div class="text col-md-6 col-12 col-sm-12 col-lg-6 col-xl-6 p-60">

                <div [innerHTML]="(description) " class=""></div>
                <br>
                <a >
                    {{ 'MAIN.LINK'  }}
                    <img alt="" class="icon"    src="assets/images/next.png">
                </a>
            </div>
        </div>
    </div>
</div>
<!----------------------------banner in slider------------------------------->
<div *ngIf="type == 'bannerHomePage'" [ngStyle]="{'background-color':'#fff', 'border-radius':'16px'}" class="container"
     id="bannerHomePage">
    <div class="banner-home-page-item">
        <div class="banner-home-page-item-container row">
            <div class="col-md-6 col-12	col-sm-12 col-lg-6 col-xl-6 left-side p-0">
                <div [innerHTML]="description " class="banner-html-part">

                </div>
                <div class="action">
                    <a *ngIf="learn" class="main-action">
                        {{ 'MAIN.LINK'  }}
                    </a>
                </div>
            </div>
            <div class="col-md-6 col-12 col-sm-12 col-lg-6 col-xl-6 right-side p-0">
                <div class="action">
                    <a *ngIf="learn"  class="main-action">
                        {{ 'MAIN.LINK' }}
                    </a>
                </div>
                <div>
                    <img *ngIf="imgSrc.length>0" [src]="imgSrc" alt="Random slide" class="image-banner" onerror="this.src='assets/images/img/image%201.png';">
                </div>
            </div>
        </div>

    </div>
</div>

<!---------------------------------------------bannerInWebSeo------------------->
<div *ngIf="type == 'bannerInWebSeo'" [ngStyle]="{'background':backgroundColors[index%2], 'color': colors[index%2]}"
     class="card pl-60"
     id="offer">
    <div class="d-flex flex-column">
        <div>
            <h2 [ngStyle]="{'color': colors[index%2]}" class="offer-title">{{ package.title[0].content }}</h2>
        </div>
        <div class="d-flex flex-row justify-content-between bottom">
            <div class="d-flex flex-column justify-content-between col-md-5 p-0">
                <div [innerHTML]="(description)" class="sec_{{index%2}}">
                </div>
                <div>
                    <a [ngStyle]="{'background':buttonBack[index%2], 'color': buttonColor[index%2]}"
                       class="primaryLink">
                        <span>{{ 'all_services' }}</span>
                        <img *ngIf="index%2 === 0" src="/assets/images/next.png"/>
                        <img *ngIf="index%2 === 1" src="/assets/images/next.png"/>
                    </a>
                </div>
            </div>
            <div class="col-md-7 p-0">
                <img class="image-banner"  src="{{imgSrc}}" onerror="this.src='assets/images/img/image%201.png';">
            </div>
        </div>

    </div>
</div>
<!-----------------------------------banner structure------------------->
<section *ngIf="type == 'bannerStructure'" [ngStyle]="{'background-color':color}" id="bannerStructure">
    <div class="container">
        <div class="row">
            <img *ngIf="imgSrc.length>0" alt="first slide" class="d-block img-fluid"
                 onerror="this.src='assets/images/img/image%201.png';"
                 src="{{imgSrc}}">
        </div>
    </div>
</section>
<!----------------------------banner default------------------------------->
<div *ngIf="type == 'default'" [ngStyle]="{'background-color':'#fff', 'border-radius':'16px'}" class="container"
     id="bannerDefault">
    <div style="">
        <div class="row">
            <div class="col-md-12 col-12 col-sm-12 col-lg-7 col-xl-7  flex-fill">
                <div class="d-flex flex-column left_sec">
                    <div [innerHTML]="description" class="banner-html-part">
                    </div>
                    <div class="action">
                        <a *ngIf="learn" class="main-action">
                            {{ 'MAIN.LINK'  }}
                        </a>
                    </div>
                </div>

            </div>
            <div class="col-md-12 col-12 col-sm-12 col-lg-5 col-xl-5">
                <div class="img">
                    <img *ngIf="imgSrc.length>0" [src]="imgSrc" alt="Random slide" class="image-banner" onerror="this.src='assets/images/img/image%201.png';">
                </div>
            </div>
        </div>

    </div>

</div>

<!----------------------------banner in slider------------------------------->
<div *ngIf="type == 'banner_with_breadcrumbs'" [ngStyle]="{'background-color':'#fff', 'border-radius':'16px'}" class="p-60"
     id="banner_with_breadcrumbs">
    <div class="bwb-item container">
        <div class="banner-home-page-item-container row">

            <div class="col-md-6 col-12	col-sm-12 col-lg-6 col-xl-6 left-side ">
<!--                <app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>-->
                <div [innerHTML]="description " class="banner-html-part">

                </div>
                <div class="action">
                    <a *ngIf="learn" class="main-action">
                        {{ 'MAIN.LINK'  }}
                    </a>
                </div>
            </div>
            <div class="col-md-6 col-12 col-sm-12 col-lg-6 col-xl-6 right-side ">
                <div class="d-flex flex-column">
                    <img *ngIf="imgSrc.length>0" [src]="imgSrc" alt="Random slide" class="image-banner"
                         onerror="this.src='assets/images/img/image%201.png';">
                </div>
            </div>
        </div>

    </div>
</div>