<div class="col">
  <div ngbDropdown class="d-inline-block ">
    <button class="btn " id="dropdownBasic1" ngbDropdownToggle><img src="../../../../../assets/images/nav/am.svg" class="langImg"></button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem><img src="../../../../../assets/images/nav/am.svg" class="langImg">hy</button>
      <button ngbDropdownItem><img src="../../../../../assets/images/nav/en.png" class="langImg">en</button>
      <button ngbDropdownItem><img src="../../../../../assets/images/nav/ru.png" class="langImg">ru</button>
    </div>
  </div>
</div>

