
<nav class="navbar navbar-expand-md navbar-light bg-light">
  <!-- Container wrapper -->
  <div class="container-fluid">
    <!-- Toggle button -->
    <a class="navbar-brand mt-2 mt-lg-0" href="#">
      <img src="../../../../assets/images/nav/Logo.svg" alt="">
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Collapsible wrapper -->

    <div class="collapse navbar-collapse" id="navbarSupportedContent" >
      <!-- Navbar brand -->
      <!-- Left links -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown">
          <a class="nav-link " data-bs-toggle="dropdown" href="#">Individuals</a>
          <table class="dropdown-menu">
            <tr>
              <th> Services</th>
              <th> Terms</th>
              <th> Calculators</th>
            </tr>
            <tr>
              <td><a class="dropdown-item" href="#"> Loans</a> </td>
              <td><a class="dropdown-item" href="#"> Terms</a></td>
              <td><a class="dropdown-item" href="#"> Loan calculator</a></td>
            </tr>
            <tr>
              <td><a class="dropdown-item" href="#"> Transfers</a></td>
              <td><a class="dropdown-item" href="#"> Loan</a></td>
              <td><a class="dropdown-item" href="#"> Deposite Calculator</a></td>
            </tr>
            <tr>
              <td><a class="dropdown-item" href="#"> Accounts</a> </td>
              <td><a class="dropdown-item" href="#"> Transfers</a></td>
              <td><a class="dropdown-item" href="#"> Other calculators</a></td>
            </tr>
            <tr>
              <td><a class="dropdown-item" href="#"> Investors</a> </td>
              <td><a class="dropdown-item" href="#"> Guarnaty</a></td>
            </tr>
            <tr>
              <td><a class="dropdown-item" href="#"> Shetab Cards</a> </td>
              <td><a class="dropdown-item" href="#"> Accounts</a></td>
            </tr>
            <tr>
              <td><a class="dropdown-item" href="#"> Escrow</a> </td>
              <td><a class="dropdown-item" href="#"> Investments</a></td>
            </tr>
            <tr>
              <td></td>
              <td><a class="dropdown-item" href="#"> Deposite box</a></td>
            </tr>
            <tr>
              <td></td>
              <td><a class="dropdown-item" href="#"> Escrow</a></td>
            </tr>
            <tr>
              <td></td>
              <td><a class="dropdown-item" href="#"> Other Terms</a></td>
            </tr>
          </table>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Businesses</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Services</a>
        </li>
      </ul>

    <!-- Collapsible wrapper -->

    <!-- Right elements -->
    <div class="d-flex align-items-center rightElements">
      <!-- Icon -->
      <a class="text-reset me-3" href="#">
        <img src="../../../../assets/images/nav/LocationLocation.svg" alt="location">
      </a>
      <a class="text-reset me-3" href="#">
        <img src="../../../../assets/images/nav/Call.svg" alt="Call">
      </a>
      <a class="text-reset me-3" href="#">
        <app-select-language></app-select-language>
      </a>

        <app-full-nav></app-full-nav>


    </div>
    </div>
  </div>
</nav>
<!-- Navbar -->
