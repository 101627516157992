import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AlertModule, BreadcrumbModule, CardModule, ModalModule} from './components';
import {DataFilterPipe} from './components/data-table/data-filter.pipe';
import {TodoListRemoveDirective} from './components/todo/todo-list-remove.directive';
import {TodoCardCompleteDirective} from './components/todo/todo-card-complete.directive';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';

import {SpinnerComponent} from './components/spinner/spinner.component';
import {ApexChartComponent} from './components/chart/apex-chart/apex-chart.component';
import {ApexChartService} from './components/chart/apex-chart/apex-chart.service';
import {ToastComponent} from './components/toast/toast.component';
import {ToastService} from './components/toast/toast.service';
import {GalleryComponent} from './components/gallery/gallery.component';
import {LightboxModule} from 'ngx-lightbox';
import {ModalBasicComponent} from "./components/modal-basic/modal-basic.component";
import {PaginationCustomComponent} from "./components/pagination-custom/pagination-custom.component";
import {SortHeaderCustomComponent} from "./components/sort-header-custom/sort-header-custom.component";
import {EditModalComponent} from "./components/edit-modal/edit-modal.component";
import {ValidationErrorComponent} from "./components/validation-error/validation-error.component";
import {PopupHelper} from "../../core/helpers/popup.helper";
import {NotificationsHelper} from "../../core/helpers/notifications.helper";
import {NetworkHelper} from "../../core/helpers/network.helper";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {MaskLoaderComponent} from "./components/mask-loader/mask-loader.component";
import {SafeHtml} from "./pipe/SafeHtml";
import {InputFormComponent} from "./components/input-form/input-form.component";
import {SelectModule} from "ng-select";
import 'tinymce/plugins/colorpicker/plugin.js';
import 'tinymce/plugins/textcolor/plugin.js';
import {TinymceModule} from "angular2-tinymce";
import {NgbDatepickerModule, NgbTabsetModule} from "@ng-bootstrap/ng-bootstrap";
import {AmazingTimePickerModule} from "amazing-time-picker";
import {
    InputTranslatableModelComponent
} from "./components/input-translatable-model/input-translatable-model.component";
import {InputModelComponent} from "./components/input-model/input-model.component";
import {InputAutocompleteComponent} from "./components/input-autocomplete/input-autocomplete.component";
import {ColorPickerModule} from "ngx-color-picker";
import {FileUploaderComponent} from "./components/file-uploader/file-uploader.component";
import {MaskLoaderPrivateComponent} from "./components/mask-loader-private/mask-loader-private.component";
import {CustomModalComponent} from "./components/custom-modal/custom-modal.component";
import {MosaicImagesComponent} from "./components/mosaic-images/mosaic-images.component";
import {CustomInputComponent} from "./components/custom-input/custom-input.component";
import {UserImagesComponent} from "./components/user-images/user-images.component";
import {PrimaryButtonComponent} from "./components/primary-button/primary-button.component";
import {SecondaryButtonComponent} from "./components/secondary-button/secondary-button.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {DepositCalculatorComponent} from "./components/deposit-calculator/deposit-calculator.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SelectModule,
        TinymceModule.withConfig({
            plugins: ['textcolor', 'colorpicker','table','advlist  lists link'],
            toolbar: ' undo redo | styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent| bullist numlist | link | fontselect',
            textcolor_cols:  7,
            textcolor_rows:  8,
            font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            textcolor_map: [
                "FFFFFF", "White",
                '000000', 'Black',
                "343434", "343434",
                "4D4D4D", "4D4D4D",
                "F3F5F7", "F3F5F7",
                "D81A3D", "D81A3D",
                "ffaa00", "ffaa00",

                'FF0000', 'Red',
                'FFFF00', 'Yellow',
                '008000', 'Green',
                '0000FF', 'Blue',
                "993300", "Burnt orange",
                "000080", "Navy Blue",
                "333399", "Indigo",

                "333333", "Very dark gray",
                "800000", "Maroon",
                "FF6600", "Orange",
                "808000", "Olive",
                "008000", "Green",
                "008080", "Teal",
                "0000FF", "Blue",

                "666699", "Grayish blue",
                "808080", "Gray",
                "FF0000", "Red",
                "FF9900", "Amber",
                "99CC00", "Yellow green",
                "339966", "Sea green",
                "33CCCC", "Turquoise",

                "3366FF", "Royal blue",
                "800080", "Purple",
                "999999", "Medium gray",
                "FF00FF", "Magenta",
                "FFCC00", "Gold",
                "FFFF00", "Yellow",
                "00FF00", "Lime",

                "00FFFF", "Aqua",
                "00CCFF", "Sky blue",
                "993366", "Red violet",
                "FF99CC", "Pink",
                "FFCC99", "Peach",
                "FFFF99", "Light yellow",
                "CCFFCC", "Pale green",

                "CCFFFF", "Pale cyan",
                "99CCFF", "Light sky blue",
                "CC99FF", "Plum",
            ]
        }),
        AlertModule,
        CardModule,
        BreadcrumbModule,
        AmazingTimePickerModule,
        ColorPickerModule,
        NgbDatepickerModule,
        NgbTabsetModule,
        ModalModule,
        ClickOutsideModule,
        LightboxModule,
        ImageCropperModule,
    ],
    exports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule,
        CardModule,
        TinymceModule,
        BreadcrumbModule,
        NgbTabsetModule,
        ModalModule,
        NgbDatepickerModule,
        ColorPickerModule,
        AmazingTimePickerModule,
        DataFilterPipe,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        ClickOutsideModule,
        SpinnerComponent,
        ApexChartComponent,
        GalleryComponent,
        ModalBasicComponent,
        PaginationCustomComponent,
        SortHeaderCustomComponent,
        EditModalComponent,
        ValidationErrorComponent,
        InputTranslatableModelComponent,
        InputModelComponent,
        MaskLoaderComponent,
        InputFormComponent,
        InputAutocompleteComponent,
        FileUploaderComponent,
        SafeHtml,
        ToastComponent,
        UserImagesComponent,
        DepositCalculatorComponent
    ],
    declarations: [
        DataFilterPipe,
        SafeHtml,
        TodoListRemoveDirective,
        TodoCardCompleteDirective,
        InputTranslatableModelComponent,
        InputModelComponent,
        SpinnerComponent,
        MaskLoaderComponent,
        InputAutocompleteComponent,
        ApexChartComponent,
        ModalBasicComponent,
        FileUploaderComponent,
        ToastComponent,
        PaginationCustomComponent,
        InputFormComponent,
        SortHeaderCustomComponent,
        EditModalComponent,
        ValidationErrorComponent,
        GalleryComponent,
        MaskLoaderPrivateComponent,
        CustomModalComponent,
        FileUploaderComponent,
        UserImagesComponent,
        MosaicImagesComponent,
        CustomInputComponent,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        DepositCalculatorComponent
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        ApexChartService,
        ToastService,
        NotificationsHelper, NetworkHelper, HttpClient,
        PopupHelper
    ]
})
export class SharedModule {
}
