import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-nav',
  templateUrl: './full-nav.component.html',
  styleUrls: ['./full-nav.component.scss']
})
export class FullNavComponent implements OnInit {
  bool = false
  drop = false
  navElements=['Individuals','Businesses','Calculators','Tariffs','About Mellat']
  elem = ''
  dropElem = ''
  dropElemText = 'We seek excellence in each digital product we craft. Would you like to help our team create a truly' +
    ' scalable test infrastructure to ensure quality? If you love paying attention to detail and the startup environment,' +
    ' you\'ll love this position.'

  elemdrop = {
    'Individuals' : ['Loans', 'Transfers', 'Accounting', 'Investment', 'Escrow', 'Shetab Cards'],
    'Businesses' : ['Business', 'Accounts', 'Investments', 'Acreditiv', 'Transfers', 'Guaranty','Escrow'],
    'Calculators' : ['Loan Calculator', 'Depossit Calculator', 'Other Calculators'],
    'Tariffs' : ['Letter Of Credit', 'Loan', 'Transfers', 'Guaranty', 'Accounts', 'Investments','Deposit','Escrow'],
    'About Mellat' : ['Bank Management', 'Significant Shareholders', 'Corporate Governance', 'Achievements of the Bank', 'Ethics in Mellat Bank', 'Bank Structure'],

  }

  openNav(){
    this.bool=(!this.bool);
  }

  openDrop(dropMenu: HTMLDivElement, nav: string, text: HTMLDivElement){
    this.elem = nav;
    this.drop=(!this.drop);
    dropMenu.classList.toggle('d-block');
    text.classList.remove('d-block')
  }
  constructor() { }

  ngOnInit(): void {
  }

  showText(element: string,  text: HTMLDivElement) {
    text.classList.add('d-block');
    this.dropElem = element
  }
}
