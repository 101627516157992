import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {StorageService} from './storage.service';
import {catchError, map} from 'rxjs/operators';
import {ResponseHelper} from './response.helper';
import {EventManager} from './event-manager';
import {throwError} from 'rxjs';
import {NotificationsHelper} from './notifications.helper';


@Injectable()
export class NetworkHelper {

    constructor(public http: HttpClient,
                public eventManager: EventManager,
                public popup: NotificationsHelper) {
        NetworkHelper.popupHelper = this.popup;
        NetworkHelper.eventManagerHelper = this.eventManager;
    }

    static requestQueue = [];
    static popupHelper: any;
    static eventManagerHelper: any;

    public checkQueue() {
        NetworkHelper.requestQueue.push(1);
        if (NetworkHelper.requestQueue.length === 1) {
            this.eventManager.shiwLoader();
        }
    }

    static prepareHttpOptions(customToken?: string): HttpHeaders {
        const headerObj = {};
        if (StorageService.getToken()) {
            headerObj['Authorization'] = environment.authHeaderPrefix + StorageService.getToken().toString() + environment.authHeaderSufix;
        }

        return new HttpHeaders(headerObj);
    }

    static prepareOptionsFile(customToken?: string): HttpHeaders {
        const headerObj = {};
        if (StorageService.getToken()) {
            headerObj['Authorization'] = environment.authHeaderPrefix + StorageService.getToken().toString() + environment.authHeaderSufix;
            headerObj['responseType'] = 'text';
        }

        return new HttpHeaders(headerObj);
    }

    static prepareFormDataHttpOptions(customToken?: string): HttpHeaders {
        const headerObj = {};
        if (StorageService.getToken()) {
            headerObj['Authorization'] = environment.authHeaderPrefix + StorageService.getToken().toString() + environment.authHeaderSufix;
        }


        return new HttpHeaders(headerObj);
    }

    getWithoutLoader<T>(url, jsonData?, customToken?: string): Observable<ResponseHelper> {
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        let params: HttpParams;
        let queryString = '';
        if (jsonData !== null && jsonData !== undefined) {
            queryString = Object.keys(jsonData).map(key => (jsonData[key])?key + '=' + jsonData[key] + '&':'').join('');
        }
        return this.formatedResponse(this.http.get<any>(url + queryString.slice(0, -1), {headers: headers, params: params}));
    }

    get<T>(url, jsonData?, customToken?: string): Observable<ResponseHelper> {
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        this.checkQueue();
        let params: HttpParams;
        let queryString = '';
        if (jsonData !== null && jsonData !== undefined) {

            queryString = this.concatGetParams(jsonData);

        }
        return this.formatedResponse(this.http.get<any>(url + '?' + queryString, {headers: headers}));
    }

    post<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        return this.formatedResponse(this.http.post<T>(url, data, {headers: headers}));
    }


    postFormData<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareFormDataHttpOptions(customToken);
        return this.formatedResponse(this.http.post<T>(url, data, {headers: headers}));
    }

    putFormData<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareFormDataHttpOptions(customToken);
        return this.formatedResponse(this.http.put<T>(url, data, {headers: headers}));
    }

    put<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        return this.formatedResponse(this.http.put<T>(url, data, {headers: headers}));
    }

    patch<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        return this.formatedResponse(this.http.patch<T>(url, data, {headers: headers}));
    }


    downloadFile(url: string, customToken?: string): Observable<any> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareOptionsFile(customToken);
        return this.http.get(url, {headers: headers, responseType: 'text'}).pipe((data) => {
            NetworkHelper.eventManagerHelper.hideLoader();
            return data;
        });
    }

    del<T>(url: string, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        return this.formatedResponse(this.http.delete<T>(url, {headers: headers}));
    }

    formatedResponse(obj) {
        return obj.pipe(catchError(this.handleError)).pipe(map((res: any) => {
            NetworkHelper.requestQueue.splice(0, 1);
            if (NetworkHelper.requestQueue.length === 0) {
                this.eventManager.hideLoader();
            }
            if(res.status === 401) {
                NetworkHelper.popupHelper.printError('Not authorized');
                localStorage.clear();
                location.reload();
            } else if(res.status === 200){

                return new ResponseHelper(res);
            } else if(res.status === 400){
                return new ResponseHelper(res);
            } else {
                NetworkHelper.eventManagerHelper.hideLoader();
                NetworkHelper.popupHelper.printError(res.message[0].message);
                return new ResponseHelper(res);
            }



            return new ResponseHelper(res);
        }));
    }

    private concatGetParams(obj:any): any {
        let num = 0;
        let paramsUrl = null;

        for (const [key, value] of Object.entries(obj)) {
            if(value !== null){
                let isArray = false
                if(key.slice(-2) === '[]'){
                    isArray = true
                }
                if (num === 0) {
                    if(isArray){
                        for (let i = 0; i < obj[key].length; i ++){
                            if(i === 0){
                                paramsUrl = `${key}=${obj[key][i]}`;
                            }else {
                                paramsUrl += `&${key}=${obj[key][i]}`;
                            }
                        }
                    }else {
                        paramsUrl = `${key}=${value}`;
                    }
                } else {
                    if(isArray){
                        for (let i = 0; i < obj[key].length; i ++){
                            paramsUrl += `&${key}=${obj[key][i]}`
                        }
                    }else {
                        paramsUrl += `&${key}=${value}`;
                    }
                }
                num++;
            }

        }
        return paramsUrl;
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            debugger;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
        }
        NetworkHelper.requestQueue.splice(0, 1);
        if (NetworkHelper.requestQueue.length === 0) {
            NetworkHelper.eventManagerHelper.hideLoader();
        }
        NetworkHelper.popupHelper.printError('something went wrong. Please check your network connection');
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
}
