import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-sort-header-custom',
    templateUrl: './sort-header-custom.component.html'
})
export class SortHeaderCustomComponent implements OnInit, OnChanges {
    @Input() name: string;
    @Input() orderBy: string;
    @Input() sortable: boolean;
    @Input() sorted: string;
    @Output() sortClick: EventEmitter<any> = new EventEmitter();

    public order = -1;

    constructor() {
    }


    sort() {
        if (this.order === -1) {
            this.order = 1;
        } else {
            this.order = -1;
        }
        this.sortClick.emit({orderBy: this.orderBy, order: this.order});
    }


    ngOnInit() {

    }

    ngOnChanges() {
    }


}
