import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-related-page',
  templateUrl: './related-page.component.html',
  styleUrls: ['./related-page.component.scss']
})
export class RelatedPageComponent implements OnInit {
  active = 'History';
  tabName = ['History','Bank significant paricipants','Managers','Company','Achivements','Corporate governance','Human resources','Missions']
  constructor() { }

  ngOnInit(): void {
  }

}
