import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mellat-service',
  templateUrl: './mellat-service.component.html',
  styleUrls: ['./mellat-service.component.scss']
})
export class MellatServiceComponent implements OnInit {
  questions =['Loans','Transfers','Accounts','Investements']

  constructor() { }

  ngOnInit(): void {
  }

  open(collapse: HTMLDivElement, fa: HTMLElement) {
    collapse.classList.toggle('d-block')
    fa.classList.toggle('fa-angle-down')
  }
}
