<div *ngIf="type == 'Questions'" id="questions">
  <div class="container">
    <div class="title">
      <h1>{{questions.title[0].content}}</h1>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <ngb-accordion [closeOthers]="true" activeIds="static-1" class="qa">
          <ngb-panel  title="{{question.question[0].content}}" *ngFor="let question of questions.questions; index as i">
            <ng-template ngbPanelContent>
              {{question.answer[0].content}}
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type == 'Services'" class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-12	col-sm-5 col-xl-5 title">
        <h1>{{questions.title[0].content}}</h1>
      </div>
      <div class="col-lg-7 col-md-7 col-12	col-sm-7 col-xl-7">
        <ngb-accordion [closeOthers]="true" activeIds="static-1" class="services">
          <ngb-panel  title="{{question.question[0].content}}" *ngFor="let question of questions.questions; index as i">
            <ng-template ngbPanelContent>
              {{question.answer[0].content}}
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>



