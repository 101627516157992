import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  @Input() visible = false;
  @Input() class : string;
  @Input() options :any;
  @Output() hideModal: EventEmitter<any>;
  modalRef:any;

  //@ViewChild('content') content:any;
  @ViewChild('content') content: ElementRef<HTMLInputElement>;


  constructor(private modalService: NgbModal) {
    this.hideModal = new EventEmitter<any>();

  }

  ngOnInit() {
  }

  openModal() {

    console.log('modal opened')
    this.modalRef =this.modalService.open(this.content,this.options);

  }


  public hide(): void {
    this.modalRef.close()
  }


  /**
   * Open scroll modal
   * @param scrollDataModal scroll modal data
   */
  scrollModal(scrollDataModal: any) {
   // this.modalService.open(scrollDataModal, { scrollable: true });
  }

}
