import { Component, OnInit,Input } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() package;
  @Input() index:any;
  @Input() learn = false;

  mainPath = environment.filePath;
  imgSrc:string
  // message: any;
  text = []
  type: string
  public title: string;
  public description: string;
  public link: any;
  private ind: number=0;
  public color: string;
  public title_color: any;
  public description_color: any;
  backgroundColors:any = ['#fff','linear-gradient(118deg, #252525 2.57%, #4B5163 107.15%)'];
  colors:any = ['#1E222E','#fff'];
  buttonBack:any = ['#D81A3D','rgba(255, 255, 255, 0.80)'];
  buttonColor:any = ['#FFF','#343434'];
  constructor() {
    this.type = '';
    this.imgSrc = ''
    this.title = ''
    this.description = ''
    this.link = ''
    this.text = []
  }

  ngOnInit(): void {
    this.type=this.package.type
    this.color=this.package.color
    this.title_color=this.package.title_color
    this.description_color=this.package.description_color
    this.imgSrc = this.mainPath+this.package.image
    this.title = this.package.title[this.ind].content
    this.description = this.package.description[this.ind].content
    this.link = this.package.link
    this.text = this.description.split("/+");
    // if(this.message.length >= 3){
    //   for (let i = 2; i<this.message.length; i++){
    //     // @ts-ignore
    //     this.text.push(this.message[i])
    //   }
    // }

  }

}
