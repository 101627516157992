
<div id="header" >
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>{{title}}</h1>

        <nav  aria-label="breadcrumb">
          <ol class="breadcrumb">
<!--            <li class="breadcrumb-item"><a href="#">{{breadcrumbs[0]}}</a></li>-->
            <li class="breadcrumb-item "
                [ngClass]="i == this.breadcrumbs.length-1?'active':''"
                aria-current="page"
                *ngFor="let message of breadcrumbs;index as i"
               >
              {{message}}

            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
