import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../../../../../core/services/auth.service";
import {StorageService} from "../../../../../core/helpers/storage.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() { }

  logout(){
    StorageService.clear();
    this.authService.logout().subscribe((data) => {
      this.router.navigate(['/']);
      location.reload();
    })
  }
}
