import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [

  {
    id: 'admin-portal',
    title: 'Implemented features',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'subscriptions',
        title: 'CRM',
        type: 'collapse',
        icon: 'feather icon-package',
        children: [
          {
            id: 'backups',
            title: 'Backups',
            type: 'item',
            url: '/backups/list'
          },
          {
            id: 'users-list',
            title: 'Users',
            type: 'item',
            url: '/users'
          }
        ],
      },
      // {
      //   id: 'products',
      //   title: 'Products',
      //   type: 'collapse',
      //   icon: 'fa fa-trophy',
      //   children: [
      //     {
      //       id: 'loans',
      //       title: 'Loans',
      //       type: 'item',
      //       url: '/loan'
      //     }
      //   ],
      // },
      {
        id: 'landing',
        title: 'Landing',
        type: 'collapse',
        icon: 'fa fa-blog',
        children: [

          {
            id: 'category',
            title: 'Categories',
            type: 'item',
            url: '/category'
          },

          {
            id: 'banner',
            title: 'Banners',
            type: 'item',
            url: '/banner'
          },
          {
            id: 'qa',
            title: 'Questions and answers',
            type: 'item',
            url: '/qa'
          },
          {
            id: 'slider',
            title: 'Slider',
            type: 'item',
            url: '/slider'
          },
          {
            id: 'teams',
            title: 'Team',
            type: 'item',
            url: '/team'
          },
          {
            id: 'filePackages',
            title: 'File packages',
            type: 'item',
            url: '/packages'
          },
          {
            id: 'pages',
            title: 'Pages',
            type: 'item',
            url: '/pages'
          },
          {
            id: 'tabs',
            title: 'Tabs',
            type: 'item',
            url: '/tabs'
          },
        ],
      },
      {
        id: 'requests',
        title: 'Requests',
        type: 'collapse',
        icon: 'fa fa-file-invoice',
        children: [
          // {
          //   id: 'loans-data-list',
          //   title: 'Loans Requests',
          //   type: 'item',
          //   url: '/loan-data'
          // },
          {
            id: 'banking-list',
            title: 'Transfer Requests',
            type: 'item',
            url: '/transaction-requests'
          },
          // {
          //   id: 'contact-us',
          //   title: 'Contact Us',
          //   type: 'item',
          //   url: '/contact-us'
          // }
        ],
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
