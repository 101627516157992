import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-navBar',
  templateUrl: './navBar.component.html',
  styleUrls: ['./navBar.component.scss']
})
export class NavBarComponent implements OnInit {
  public currentUrl = '';
  public isCollapsed = true;
  constructor( private router: Router) { }

  ngOnInit(): void {
  }

}
