import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventManager} from '../../../../core/helpers/event-manager';
import { FormControl} from '@angular/forms';
import {FORM_TYPES} from '../../../../core/formTypes';
@Component({
    selector: 'app-input-model',
    templateUrl: './input-model.component.html',
    styleUrls: ['./input-model.component.scss'],
})
export class InputModelComponent implements OnInit{
    types = FORM_TYPES;
    @Input() type = FORM_TYPES.TEXT_INPUT;
    @Input() formControlItem: FormControl ;
    @Input() label: any;
    @Input() placeholder: any;
    @Input() styles: any;
    @Output() sendToParent: EventEmitter<any>;
    @Input() errorMessage = '';
    @Input() name = '';
    @Input() id = '';
    @Input() disabled = false;
    @Input() selectBoxOptions = [];
    @Input() itemModel: any;
    @Input()cpPresetColors
    @Input() color;

    constructor() {
        this.sendToParent = new EventEmitter<any>();
    }

    // tslint:disable-next-line:typedef
    ngOnInit() {
        console.log(this.itemModel)

    }

    // tslint:disable-next-line:typedef
    callToParent(obj){

        if (obj && obj[0]){
            this.itemModel = obj[0];
            this.sendToParent.emit(obj[0]);
        }
    }

    // tslint:disable-next-line:typedef
    change(event){
        this.itemModel = event;
        this.sendToParent.emit(event);
    }

    // tslint:disable-next-line:typedef
    getImages(obj){
    }


}
