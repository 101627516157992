<div id="loanCalc">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12">
        <div class="title">
          <h1>Loan Calculator</h1>
          <span>We understand entrepreneurs and the corporate world with
            its many challenges and opportunities.</span>
        </div>

        <form #myform="ngForm" (ngSubmit)="onSubmit(myform)" class="row">
          <div class="col-lg-6 col-md-12 col-12 col-sm-12 col-xl-6">

            <p>Loan amount</p>
            <div class="input-group mb-3">
              <input class="form-control" name="loanAmount" ngModel placeholder="Loan amount" type="number">
            </div>
            <p>Annual interest rate</p>
            <div class="input-group mb-3">
              <input class="form-control" name="rate" ngModel placeholder="%" type="number">
            </div>
            <p>This is a preliminary calculation and is not a public offer.</p>
          </div>
          <div class="col-lg-6 col-md-12 col-12 col-sm-12 col-xl-6" >
            <p>Term</p>
            <div class="input-group mb-3">
              <input class="form-control" name="term" ngModel placeholder="0 month" type="number">
            </div>
            <p>Loan servicing</p>
            <div class="input-group mb-3">
              <div class="form-check form-check-inline">
                <input checked class="form-check-input" id="inlineRadio1" name="inlineRadioOptions" ngModel
                       type="radio" value="option1">
                <label class="form-check-label" for="inlineRadio1">Differentiate</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" id="inlineRadio2" name="inlineRadioOptions" ngModel type="radio"
                       value="option2">
                <label class="form-check-label" for="inlineRadio2">Annuity</label>
              </div>
            </div>
            <div>
              <button class="btn btn-primary" type="submit">Send request</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div id="table" class="table" *ngIf="bool" >
    <div class="container">
      <div class="row">
        <div class="table">
          <div class="col-12">
              <div class="head">
                <table>
                  <thead>

                  <tr>
                    <th colspan="4"><h1>Calculation</h1></th>
                  </tr>
                  <tr>
                    <th class="header" scope="col">
                      <p>Amount</p>
                      <p>{{amount}}</p>
                    </th>
                    <th class="header" scope="col">
                      <p>Amount interest rate </p>
                      <p>{{rate}}</p>
                    </th>
                    <th class="header" scope="col">
                      <p>Loan term</p>
                      <p>{{term}}</p>
                    </th>
                    <th class="header" scope="col">
                      <p>Total (loan + interest)</p>
                      <p>{{total}}</p>
                    </th>
                  </tr>
                  </thead>
                </table>
              </div>
            <div class="body">
              <table >

                <tbody>
                <tr>
                  <th scope="row">Month</th>
                  <th scope="row">Payable interest</th>
                  <th scope="row">Partial repayment the loan</th>
                  <th scope="row">Monthly loan payment</th>

                </tr>
                <tr *ngFor="let table of calcTable ;  index as i">
                  <th scope="row"> {{table.month}}</th>
                  <td> {{table.interest}}</td>
                  <td> {{table.loan}}</td>
                  <td> {{table.payment}}</td>

                </tr>

                </tbody>

              </table>
            </div>
            <div class="footer">
              <table>
                <tfoot style="position: sticky;top: 0">
                <tr *ngIf="rate">
                  <td class="footer">Total</td>
                  <th scope="col" class="footer">{{rate*term}}</th>
                  <th scope="col" class="footer">Partial repayment the loan</th>
                  <th scope="col" class="footer">Monthly loan payment</th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
