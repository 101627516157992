import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NotAuthGuard} from './core/helpers/notauth.guard';
import {SuperadminGuard} from './core/helpers/superadmin.guard';
import {PageBuilderComponent} from './theme/layout/preview/page-builder.component';

const routes: Routes = [
  {
    path: 'builder',
    component: PageBuilderComponent,
    canActivate: [SuperadminGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./portal/Landing/page-builder/form-edit/page-edit.module').then(module => module.PageEditModule)
      },
    ]
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [SuperadminGuard],
    children: [
      {
        path: 'subscribers',
        loadChildren: () => import('./portal/CRM/subscribtions/subscriptions.module').then(module => module.SubscriptionsModule)
      },
      {
        path: 'loan',
        loadChildren: () => import('./portal/Loans/loan.module').then(module => module.LoanModule)
      },
      {
        path: 'tabs',
        loadChildren: () => import('./portal/Landing/tabs/tabs.module').then(module => module.TabsModule)
      },
      {
        path: 'qa',
        loadChildren: () => import('./portal/Landing/questions-and-answers/qa.module').then(module => module.QaModule)
      },
      {
        path: 'backups',
        loadChildren: () => import('./portal/CRM/backups/backups.module').then(module => module.BackupsModule)
      },
      {
        path: 'banner',
        loadChildren: () => import('./portal/Landing/banner/banner.module').then(module => module.BannerModule)
      },

      {
        path: 'team',
        loadChildren: () => import('./portal/Landing/team/team.module').then(module => module.TeamModule)
      },

      {
        path: 'slider',
        loadChildren: () => import('./portal/Landing/slider/slider.module').then(module => module.SliderModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./portal/CRM/users/users.module').then(module => module.UsersModule)
      },
      {
        path: 'packages',
        loadChildren: () => import('./portal/Landing/packages/package.module').then(module => module.PackageModule)
      },
      {
        path: 'category',
        loadChildren: () => import('./portal/Landing/category/category.module').then(module => module.CategoryModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./portal/Landing/page-builder/page-builder.module').then(module => module.PageBuilderModule)
      },
      {
        path: 'transaction-requests',
        loadChildren: () => import('./portal/Request/banking/banking.module').then(module => module.BankingModule)
      },
      // {
      //   path: 'requested-loans',
      //   loadChildren: () => import('./portal/CRM/submited-loans/submited-loans.module').then(module => module.SubmitedLoansModule)
      // },
      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'invoice',
        loadChildren: () => import('./demo/extension/invoice/invoice.module').then(module => module.InvoiceModule)
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        canActivate: [NotAuthGuard],
        loadChildren: () => import('./portal/Auth/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
