import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {StorageService} from './storage.service';

@Injectable()
export class NotAuthGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = StorageService.getJsonItem(StorageService.statics.currentUser);
        if (account) {
            this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url }});
            return false;
        }

        // not logged in so redirect to login page with the return url

        return true;
    }
}
