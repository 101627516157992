export class StorageService {
    static statics = {
        currentUser: 'currentUser',
        token: 'token',
        role: 'role',
        cache: {
            regions: 'regions',
            translationDefaultModel: 'translationDefaultModel'
        },
        tables: {
            subscriptions: 'subscriptions',
            bankingService: 'bankingService',
            users: 'users',
            loans: 'loans',
            tabs: 'tabs',
            category: 'category',
            slider: 'slider',
            banner: 'banner',
            'loans-data': 'loans-data',
            packages: 'packages',
            qa: 'qa',
            contactUs: 'contactUs',
            team: 'team',
            banking: 'banking',
            pages: 'pages',
        }
    };

    constructor() {
    }

    // tslint:disable-next-line:typedef
    static getItem(name) {
        return localStorage.getItem(name);
    }

    // tslint:disable-next-line:typedef
    static getJsonItem(name) {
        return JSON.parse(StorageService.getItem(name));

    }

    // tslint:disable-next-line:typedef
    static clear() {
        return localStorage.clear();

    }

    // tslint:disable-next-line:typedef
    static setItem(name, value) {
        localStorage.setItem(name, value);
    }

    // tslint:disable-next-line:typedef
    static setJsonItem(name, obj) {
        StorageService.setItem(name, JSON.stringify(obj));
    }

    // tslint:disable-next-line:typedef
    static getToken() {
        return StorageService.getItem(StorageService.statics.token);
    }

    // tslint:disable-next-line:typedef
    static getUserName() {
        return (StorageService.getJsonItem(StorageService.statics.currentUser)) ?
            StorageService.getJsonItem(StorageService.statics.currentUser).firstName : null;
    }

    // tslint:disable-next-line:typedef
    static removerItem(name) {
        localStorage.removeItem(name);
    }

    // tslint:disable-next-line:typedef
    static getUserId() {
        return (StorageService.getJsonItem(StorageService.statics.currentUser) &&
            StorageService.getJsonItem(StorageService.statics.currentUser).userId) ?
            StorageService.getJsonItem(StorageService.statics.currentUser).userId : null;
    }

    // tslint:disable-next-line:typedef
    static getUser() {
        return (StorageService.getJsonItem(StorageService.statics.currentUser)) ?
            StorageService.getJsonItem(StorageService.statics.currentUser) : null;
    }
}
