<div  class="drop-area"   (click)="fileElement.click()">
    <div class="my-form">
        <p>Drag Image here or click here for upload</p>
        <input type="file" id="fileElem" #fileElement accept="image/x-png,image/gif,image/jpeg" (change)="fileChangeEvent($event)">
        <label class="button" for="fileElem"  style="display: none">Choose Image</label>
        <img [src]="croppedImage"  class="cropper-preview"/>
    </div>
</div>

<app-modal-basic #modalDefault>
    <div class="app-modal-header">
        <h4 class="modal-title">Cropper</h4>
        <!--<button type="button" class="close basic-close" (click)="modalDefault.hide()">-->
            <!--<span aria-hidden="true">&times;</span>-->
        <!--</button>-->
    </div>
    <div class="app-modal-body">
        <div class="text-center row max-overflow">
            <div class="col-md-12">
                <image-cropper  style="width: 100%;min-height: 200px; max-height: 300px"
                                [imageChangedEvent]="imageChangedEvent"
                                [maintainAspectRatio]="false"
                                [roundCropper] = "false"
                                [resizeToWidth]="settings.resizeToWidth"
                                [transform]="transform"
                                (imageCropped)="imageCropped($event.base64)"
                                (imageLoaded)="imageLoaded()"
                                (loadImageFailed)="loadImageFailed()"
                                (click)="disableClick()"
                                alignImage="center"
                ></image-cropper>
            </div>
            <div class="col-md-12">
                <img [src]="croppedImage" class="cropper-preview"/>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-default  light  m-r-20" (click)="modalDefault.hide()">Close</button>
        <button type="button" class="btn btn-primary  " (click)="confirmChangeForImage()">Crop</button>
    </div>
</app-modal-basic>
