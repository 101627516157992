<div id="businessMain">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div id='gfg' class="row" >
          <button (click)="get('individual')" class="col-6	col-sm-6	col-md-6	col-lg-6	col-xl-6">
            <h3 [ngClass]="index == 'individual'? 'active' : 'noactive'" style="float: right">For Individuals</h3>
          </button>
          <button (click)="get('business')" class="col-6	col-sm-6	col-md-6	col-lg-6	col-xl-6" >
            <h3 [ngClass]="index == 'business'? 'active' : 'noactive'" style="float: left">For Businesses</h3>
          </button>
        </div>


        <div class="row">
          <div
            *ngFor="let card of cards; index as i"

            class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6"
            [ngClass]="card.category == index? 'd-block':'d-none' ">
            <div   class="card">
              <h1>{{card.title}}</h1>
              <p class="date">
                <img src="assets/images/img/TimeCircle.png" alt="">
                Last update{{card.data}}&nbsp;&nbsp;<i>{{card.card}}</i>
              </p>
              <p class="text">{{card.text}}</p>
              <a>
                more detaisl &ensp; &ensp; &nbsp;&nbsp;
                <i class="fa fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

