import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
interface News {
  img: string;
  data: string;
  text: string;
}
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  @Input() NEWS:any;
  news = [];
  mainPath = environment.filePath
  constructor() { }

  ngOnInit(): void {
    this.news = this.NEWS.docs;
  }

}
