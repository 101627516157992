import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FORM_TYPES} from "../../../../core/formTypes";
import {StorageService} from "../../../../core/helpers/storage.service";
@Component({
    selector: 'app-input-translatable-model',
    templateUrl: './input-translatable-model.component.html',
    styleUrls: ['./input-translatable-model.component.scss'],
})
export class InputTranslatableModelComponent implements OnInit {
    types = FORM_TYPES;
    @Input() languages = StorageService.getJsonItem(StorageService.statics.cache.regions);
    @Input() type = FORM_TYPES.TEXT_INPUT;
    @Input() label: any;
    @Input() placeholder: any;
    @Input() styles: any;
    @Input() sendToParent: EventEmitter<any>;
    @Input() name = '';
    @Input() id = '';
    @Input() disabled = false;
    @Input() itemModel;

    constructor() {
        this.sendToParent = new EventEmitter<any>();
    }

    stopPropogate(e){
        e.stopPropagation();
    }

    ngOnInit() {
    }

}
