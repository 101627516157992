<div id="blogMain">
  <div class="container">
    <div class="row">
<!--      <div class="col-12">-->
<!--        <div id='gfg' >-->
<!--          <tabset>-->
<!--            <tab  class="">-->
<!--              <div *tabHeading class="head">-->
<!--                All-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div-->
<!--                        *ngFor="let card of blog; index as i"-->

<!--                        class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6">-->
<!--                  <div class="card">-->
<!--                    <h1>{{card.title}}</h1>-->
<!--                    <p class="date">-->
<!--                      <img src="assets/images/img/TimeCircle.png" alt="">-->
<!--                      Last update{{card.data}}&nbsp;&nbsp;<i>{{card.card}}</i>-->
<!--                    </p>-->
<!--                    <p class="text">{{card.text}}</p>-->
<!--                    <a>-->
<!--                      more detaisl &ensp; &ensp; &nbsp;&nbsp;-->
<!--                      <i class="fa fa-arrow-right"></i>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </tab>-->
<!--            <tab>-->
<!--              <div *tabHeading class="head">-->
<!--                head-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div-->
<!--                        *ngFor="let card of blog; index as i"-->

<!--                        class="col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" [ngClass]="card.category == 'Business'? 'd-block':'d-none' ">-->
<!--                  <div   class="card">-->
<!--                    <h1>{{card.title}}</h1>-->
<!--                    <p class="date">-->
<!--                      <img src="assets/images/img/TimeCircle.png" alt="">-->
<!--                      Last update{{card.data}}&nbsp;&nbsp;<i>{{card.card}}</i>-->
<!--                    </p>-->
<!--                    <p class="text">{{card.text}}</p>-->
<!--                    <a>-->
<!--                      more detaisl &ensp; &ensp; &nbsp;&nbsp;-->
<!--                      <i class="fa fa-arrow-right"></i>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </tab>-->
<!--            <tab>-->
<!--              <div *tabHeading class="head">-->
<!--                Annual reports-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div-->
<!--                        *ngFor="let card of blog; index as i"-->

<!--                        class=" col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" [ngClass]="card.category == 'Annual reports'? 'd-block':'d-none' ">-->
<!--                  <div   class="card">-->
<!--                    <h1>{{card.title}}</h1>-->
<!--                    <p class="date">-->
<!--                      <img src="assets/images/img/TimeCircle.png" alt="">-->
<!--                      Last update{{card.data}}&nbsp;&nbsp;<i>{{card.card}}</i>-->
<!--                    </p>-->
<!--                    <p class="text">{{card.text}}</p>-->
<!--                    <a>-->
<!--                      more detaisl &ensp; &ensp; &nbsp;&nbsp;-->
<!--                      <i class="fa fa-arrow-right"></i>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </tab>-->
<!--            <tab >-->
<!--              <div *tabHeading class="head">-->
<!--                Finance-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div-->
<!--                        *ngFor="let card of blog; index as i"-->

<!--                        class=" col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" [ngClass]="card.category == 'Finance'? 'd-block':'d-none' ">-->
<!--                  <div   class="card">-->
<!--                    <h1>{{card.title}}</h1>-->
<!--                    <p class="date">-->
<!--                      <img src="assets/images/img/TimeCircle.png" alt="">-->
<!--                      Last update{{card.data}}&nbsp;&nbsp;<i>{{card.card}}</i>-->
<!--                    </p>-->
<!--                    <p class="text">{{card.text}}</p>-->
<!--                    <a>-->
<!--                      more detaisl &ensp; &ensp; &nbsp;&nbsp;-->
<!--                      <i class="fa fa-arrow-right"></i>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </tab>-->
<!--            <tab >-->
<!--              <div *tabHeading class="head">-->
<!--                For Businesses-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div-->
<!--                        *ngFor="let card of blog; index as i"-->

<!--                        class=" col-12	col-sm-12	col-md-6	col-lg-6	col-xl-6" [ngClass]="card.category == 'For Businesses'? 'd-block':'d-none' ">-->
<!--                  <div   class="card">-->
<!--                    <h1>{{card.title}}</h1>-->
<!--                    <p class="date">-->
<!--                      <img src="assets/images/img/TimeCircle.png" alt="">-->
<!--                      Last update{{card.data}}&nbsp;&nbsp;<i>{{card.card}}</i>-->
<!--                    </p>-->
<!--                    <p class="text">{{card.text}}</p>-->
<!--                    <a>-->
<!--                      more detaisl &ensp; &ensp; &nbsp;&nbsp;-->
<!--                      <i class="fa fa-arrow-right"></i>-->
<!--                    </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </tab>-->
<!--          </tabset>-->
<!--        </div>-->

<!--      </div>-->
    </div>
  </div>
</div>
