import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl} from '@angular/forms';
import { FORM_TYPES } from "../../../../core/formTypes";

@Component({
    selector: 'app-input-autocomplete',
    templateUrl: './input-autocomplete.component.html',
    styleUrls: ['./input-autocomplete.component.scss'],
})
export class InputAutocompleteComponent implements OnInit{
    types = FORM_TYPES;
    requestSend:any;
    timeOut:any;
    @Input() useModel = false;
    @Input() type = FORM_TYPES.TEXT_INPUT;
    @Input() formControlItem: FormControl;
    @Input() label: any;
    @Input() callToBackendFunction: any;
    @Input() placeholder: any;
    @Input() styles: any;
    @Output() sendToParent: EventEmitter<any>;
    @Input() errorMessage = '';
    @Input() name = '';
    @Input() id = '';
    @Input() disabled = false;
    @Input() noneOfThis = false;
    @Input() selectBoxOptions = [];
    @Input() setedItem;
    @Input() itemModel;

    constructor() {
        this.sendToParent = new EventEmitter<any>();
    }

    ngOnInit() {
        this.change('');
    }

    modelChange(data) {
        this.sendToParent.emit(data);
    }

    change(searchKey = ''){
        if(this.timeOut){
            clearTimeout(this.timeOut);
            this.timeOut = null;
        }
        this.timeOut = setTimeout(() => {
            if(this.requestSend){
                this.requestSend.unsubscribe();
                this.requestSend = null;
                console.log('cancel');
            }
            this.requestSend = this.callToBackendFunction.search(searchKey).subscribe( data => {
                this.selectBoxOptions = this.setedItem ? [this.setedItem]: [];
                if(this.noneOfThis){
                    this.selectBoxOptions.push({label: 'None', value: ''});
                }
                if(data.data.data.length>0){
                    for(let key in data.data.data){
                        if((this.setedItem && this.setedItem.value !== data.data.data[key]._id) || !this.setedItem) {
                            this.selectBoxOptions.push({
                                label: data.data.data[key].name || data.data.data[key].key,
                                value: data.data.data[key]._id
                            });
                        }
                    }
                }else{
                    for(let key in data.data.data.docs){
                        if((this.setedItem && this.setedItem.value !== data.data.data.docs[key]._id) || !this.setedItem) {
                            this.selectBoxOptions.push({
                                label: data.data.data.docs[key].name || data.data.data.docs[key].key,
                                value: data.data.data.docs[key]._id
                            });
                        }
                    }
                }

                this.requestSend = null;
            })
            this.timeOut = null;
        }, 500)

    }


}
