import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {FileUploader} from 'ng2-file-upload';
import {PlatformLocation} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {PopupHelper} from "../../../../core/helpers/popup.helper";
import { ImageTransform } from 'ngx-image-cropper';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnChanges {

    imageChangedEvent: any = '';
    transform: ImageTransform = {};
    croppedImage: any = '';
    dropArea: HTMLElement;
    @Output() imageSrc = new EventEmitter();
    @Input() defaultImage = '';
    @Input() initialImage = '';
    @Input() showRemove = false;

    @Input() settings = {
        ratio: 1 / 1,
        resizeToWidth: 1024,
    };

    @ViewChild('fileForm') fileForm;
    @ViewChild('fileElement') fileElement;
    @ViewChild('modalDefault') modalDefault;
    filecontent: FileList;


    uploader: FileUploader = new FileUploader({
        url: environment.apiURL,
        isHTML5: true
    });

    constructor(platformLocation: PlatformLocation,
                private sanitizer: DomSanitizer,
                public popupHelper: PopupHelper) {
        if (!this.defaultImage) {
            //this.defaultImage = (platformLocation as any).location.origin + '/assets/images/image26.png';
        }
    }


    toDataURL(url, callback) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
            const reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    getExtension(filename) {
        const parts = filename.split('.');
        return parts[parts.length - 1];
    }

    isImage(filename) {
        const ext = this.getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'png':
                return true;
        }
        return false;
    }

    fileChangeEvent(event: any): void {
        let isImage = false;
        if (!event.isTrusted) {
            if (event.srcElement) {
                event.srcElement.files = this.filecontent;
                isImage = this.isImage(event.srcElement.files[0].name);
            } else {
                event.originalTarget.files = this.filecontent;
                isImage = this.isImage(event.originalTarget.files[0].name);
            }
        } else {
            if (event.srcElement) {
                this.filecontent = event.srcElement.files;
                isImage = this.isImage(event.srcElement.files[0].name);
            } else {
                this.filecontent = event.originalTarget.files;
                isImage = this.isImage(event.originalTarget.files[0].name);
            }

        }
        if (isImage) {
            if (this.filecontent.length > 0) {
                this.modalDefault.show();
            }
            this.imageChangedEvent = event;
        } else {
            this.popupHelper.printError('This format is not supported for image upload');
        }
    }

    imageCropped(image: string) {
        this.croppedImage = image;
    }

    imageLoaded() {
        // show cropper
    }

    loadImageFailed() {
        // show message
    }

    disableClick() {
        return false;
    }


    preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    highlight(e) {
        e.currentTarget.classList.add('highlight');
    }

    unhighlight(e) {
        e.currentTarget.classList.remove('highlight');
    }

    ngOnInit() {
        const selfObj = this;
        if (this.initialImage && this.initialImage.length > 0) {

            selfObj.croppedImage = this.sanitize(this.initialImage);
        } else if (this.defaultImage.length > 0) {
            this.toDataURL(this.defaultImage, function(dataUrl) {
                if (!selfObj.croppedImage) {
                    selfObj.defaultImage = dataUrl;
                    selfObj.croppedImage = dataUrl;
                }

            });
        }
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            selfObj?.fileForm?.nativeElement.addEventListener(eventName, selfObj.preventDefaults, true);
        });
        ['dragenter', 'dragover'].forEach(eventName => {
            selfObj?.fileForm?.nativeElement.addEventListener(eventName, selfObj.highlight, true);
        });
        ['dragleave', 'drop'].forEach(eventName => {
            selfObj?.fileForm?.nativeElement.addEventListener(eventName, selfObj.unhighlight, true);
        });
        this.fileForm?.nativeElement.addEventListener('drop', function ($event) {
            const dt = $event.dataTransfer;
            selfObj.filecontent = dt.files;
            const input = selfObj.fileForm.nativeElement.querySelector('#fileElem');
            input.dispatchEvent(new Event('change'), 10);
        }, true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const selfObj = this;
        if (this.initialImage && this.initialImage.length > 0) {

            selfObj.croppedImage = this.sanitize(this.initialImage);
        } else if (this.defaultImage.length > 0) {
            this.toDataURL(this.defaultImage, (dataUrl) => {
                if (!selfObj.croppedImage || !selfObj.showRemove) {
                    selfObj.defaultImage = dataUrl;
                    selfObj.croppedImage = dataUrl;
                }
            });
        }
    }

    handleFile(file) {
        this.fileChangeEvent(file);
    }

    confirmChangeForImage() {
        this.imageSrc.emit(this.croppedImage);
        this.modalDefault.hide();
    }


    sanitize(url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
