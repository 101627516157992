import {Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-modal-edit',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent implements OnInit, OnChanges {
  @Input() showModal: any;
  @Output() hideModalEvent: EventEmitter<any>;
  public visible = false;
  public menuWidth: any;
  public menuWidthInfo: any;
  public disableActions: any;

  constructor() {
    this.hideModalEvent = new EventEmitter();
  }

  ngOnInit() {
    if (this.showModal) {
      this.show();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.showModal) {
      this.show();
    } else {
      this.hide();
    }
  }

  public show(): void {
    this.menuWidth = '100vw';
    this.menuWidthInfo = '0px';
    this.disableActions = true;
  }

  public hide(): void {
    this.hideModalEvent.emit(false);
    this.menuWidth = '0';
    this.disableActions = false;
    location.hash = '';
  }

}
