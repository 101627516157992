import {Injectable} from '@angular/core';
import {EventManager} from './event-manager';


@Injectable()
export class NotificationsHelper {

    private errorType = 'error';
    private successType = 'success';
    private infoType = 'info';
    constructor(private events: EventManager) {
    }

    printSuccess(message) {
            this.events.showNotification({title: 'Success', msg: message, type: this.successType});
    }

    printError(message) {
        this.events.showNotification({title: 'Error', msg: message, type: this.errorType});
    }

    printInfo(message) {
        this.events.showNotification({title: 'Info', msg: message, type: this.infoType});
    }


}
