<a class="text-nowrap" *ngIf="sortable" style="cursor: pointer;display: block" (click)="sort()">
    {{name}}
    <span *ngIf="order===1 && sorted == orderBy" aria-hidden="true" class="fa fa-sort-desc" style="">
    </span>
    <span *ngIf="order===-1  && sorted == orderBy" aria-hidden="true"  class="fa fa-sort-asc" style="">
    </span>
    <span *ngIf="sorted != orderBy"  aria-hidden="true"   class="fa fa-sort" style="">
    </span>
</a>
<a class="text-nowrap" *ngIf="!sortable" style="cursor: pointer;display: block" (click)="sort()">
    {{name}}
</a>