<div *ngIf="itemModel">
    <ngb-tabset type="pills">
        <ngb-tab *ngFor="let lang of languages" [title]="lang.lang" class="mb-3">
            <ng-template ngbTabContent>
                <div *ngIf="type === types.TEXT_INPUT"  class="form-group" [ngClass]="styles">
                    <label [for]="id">{{label}}</label>
                    <input [type]="type" [name]="name"
                           [id] = "id"
                           [ngClass]="disabled?'disabled' : ''"
                           [(ngModel)]="itemModel[lang.lang]"
                           [disabled]="disabled"
                           class="form-control" />
                </div>
                <div *ngIf="type === types.REACH_TEXT_INPUT"  class="form-group" [ngClass]="styles" (mousedown)="stopPropogate($event)">
                    <label [for]="id" class="cr">{{label}}</label>
                    <app-tinymce [(ngModel)]='itemModel[lang.lang]'
                                 [id] = "id"
                                 [name]="name"
                                 #tinymce='tinymce'
                                 [disabled]="disabled"

                    ></app-tinymce>
                </div>

                <div *ngIf="type === types.TEXTAREA_INPUT"  class="form-group" [ngClass]="styles">
                    <label [for]="id">{{label}}</label>
                    <textarea [name]="name"
                           [id] = "id"
                           [ngClass]="disabled?'disabled' : ''"
                           [(ngModel)]="itemModel[lang.lang]"
                           [disabled]="disabled"
                              class="form-control" >
                    </textarea>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
