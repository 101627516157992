import {Component, OnInit, LOCALE_ID, Input} from '@angular/core';
interface serv {
  title: string;
  data: string;
  card:string;
  text: string;
  category :string;
}

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
  @Input() blogListObject;
  BLOGCARD = []
  //     = [
  //   {
  //     title: 'Loans',
  //     data: '2022-01-18',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the startup environment, you\'ll love this position.',
  //     category: 'Business'
  //   },
  //   {
  //     title: 'Accounting',
  //     data: '2022-02-20',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a' +
  //         ' truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the' +
  //         ' startup environment, you\'ll love this position.',
  //     category: 'Annual reports',
  //   },
  //   {
  //     title: 'Escrow',
  //     data: '2022-02-10',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly' +
  //         ' scalable test infrastructure to ensure quality? If you love paying attention to detail and the startup' +
  //         ' environment, you\'ll love this position.',
  //     category: 'Finance'
  //   },
  //   {
  //     title: 'Accounting',
  //     data: '2022-02-20',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a' +
  //         ' truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the' +
  //         ' startup environment, you\'ll love this position.',
  //     category: 'For Businesses',
  //   },
  //   {
  //     title: 'Loans',
  //     data: '2022-01-18',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the startup environment, you\'ll love this position.',
  //     category: 'Business'
  //   },
  //   {
  //     title: 'Accounting',
  //     data: '2022-02-20',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a' +
  //         ' truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the' +
  //         ' startup environment, you\'ll love this position.',
  //     category: 'Annual reports',
  //   },
  //   {
  //     title: 'Escrow',
  //     data: '2022-02-10',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a truly' +
  //         ' scalable test infrastructure to ensure quality? If you love paying attention to detail and the startup' +
  //         ' environment, you\'ll love this position.',
  //     category: 'Finance'
  //   },
  //   {
  //     title: 'Accounting',
  //     data: '2022-02-20',
  //     card : 'Loans',
  //     text: 'We seek excellence in each digital product we craft. Would you like to help our team create a' +
  //         ' truly scalable test infrastructure to ensure quality? If you love paying attention to detail and the' +
  //         ' startup environment, you\'ll love this position.',
  //     category: 'For Businesses',
  //   },
  // ];
  constructor() { }

  ngOnInit(): void {
    this.BLOGCARD = [];
    for(let key in this.blogListObject.docs){
      this.BLOGCARD.push({
        title: this.blogListObject.docs[key].title[0].content,
        data: this.blogListObject.docs[key].updated_at,
        card : this.blogListObject.docs[key].as_article_category[0],
        text:  this.blogListObject.docs[key].short_description[0].en,
        category: this.blogListObject.docs[key].as_article_category[0],
      })
    }
  }

}
