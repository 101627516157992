import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-team-main',
  templateUrl: './team-main.component.html',
  styleUrls: ['./team-main.component.scss']
})
export class TeamMainComponent implements OnInit {

  @Input() team;
  main:Array<any> = []
  secont:Array<any> = []
  mainPath = environment.filePath;
  public ind: number = 0 ;

  constructor() { }

  ngOnInit(): void {
    for (let i = 0; i < this.team.length; i++){
      this.team[i].type == 'mainPositions'? this.main.push(this.team[i]): this.secont.push(this.team[i])
    }

  }

}
