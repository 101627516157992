<div id="loanCalc">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12">
                <div class="title">
                    <h1>Deposit Calculator</h1>
                </div>
                <form class="row">
                    <div class="col-lg-6 col-md-12 col-12 col-sm-12 col-xl-6">

                        <p>Loan amount</p>
                        <div class="input-group mb-3">
                            <input class="form-control" name="loanAmount" ngModel placeholder=""
                                   type="number">
                        </div>
                        <p>Annual interest rate</p>
                        <div class="input-group mb-3">
                            <input class="form-control" name="rate" ngModel  placeholder="%" type="number">
                        </div>

                    </div>
                    <div class="col-lg-6 col-md-12 col-12 col-sm-12 col-xl-6">
                        <p>Term (Month)</p>
                        <div class="input-group mb-3">
                            <input class="form-control" name="term" type="number">
                            <p class="text">This is a preliminary calculation and is not a public offer.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12 col-sm-12 col-xl-6">
                        <div>
                            <button class="" type="submit">Calculate</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
