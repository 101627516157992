import {Injectable} from '@angular/core';
import swal from 'sweetalert2';


@Injectable()
export class PopupHelper {


    private translatable = {
        successTitle: 'Success',
        errorTitle: 'Error',
        infoTitle: 'Info',
        okButton: 'Ok',
        yesButton: 'Yes',
        noButton: 'No',
        successType: 'Success',
        confirmTitle: 'Confirm',
        errorType: 'Error',
        infoType: 'Info'
    };

    constructor() {
    }

    open(type, text) {
        let response = null;
        switch (type) {
            case this.translatable.errorType:
                response = this.printError(text);
                break;
            case this.translatable.successType:
                response = this.printSuccess(text);
                break;
            case this.translatable.infoType:
                response = this.printInfo(text);
                break;
        }
    }

    printError(text) {
        // @ts-ignore
        return swal.fire({
            title: this.translatable.errorTitle,
            text: text,
            type: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: true
        });
    }

    printSuccess(text) {
        // @ts-ignore
        return swal.fire({
            title: this.translatable.successTitle,
            text: text,
            type: 'success',
            confirmButtonText: this.translatable.okButton,
            allowOutsideClick: true
        });
    }

    printInfo(text) {
        // @ts-ignore
        return swal.fire({
            title: this.translatable.infoTitle,
            text: text,
            type: 'info',
            confirmButtonText: this.translatable.okButton,
            allowOutsideClick: true
        });
    }

    confirmationPopup(text) {
        // @ts-ignore
        return swal.fire({
            title: this.translatable.confirmTitle,
            text: text,
            type: 'question',
            confirmButtonText: this.translatable.yesButton,
            cancelButtonText: this.translatable.noButton,
            allowOutsideClick: true,
            showConfirmButton: true,
            showCancelButton: true
        });
    }
}
