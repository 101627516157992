import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CustomModalComponent} from "../custom-modal/custom-modal.component";
import {ImageSizes} from "../../../../core/constants";
import {FileUploadService} from "../../../../core/services/file-upload.service"
import {PopupHelperService} from "../../../../core/helpers/popup-helper.service"
import {ImageItemModel} from "../../../../core/model/image-item.model";
import {MosaicImagesComponent} from "../mosaic-images/mosaic-images.component";
import {FileUploaderComponent} from "../file-uploader/file-uploader.component";

@Component({
  selector: 'app-user-images',
  templateUrl: './user-images.component.html',
  styleUrls: ['./user-images.component.scss']
})
export class UserImagesComponent implements OnInit, AfterViewInit {

  @ViewChild('modal') modal: CustomModalComponent;
  @ViewChild('horizontalScrollWrapper') scrollWrapper: ElementRef<HTMLInputElement>;
  @ViewChild('sizes') sizesBlock: ElementRef<HTMLInputElement>;
  @ViewChild('mosaicImagesComponent') mosaicImagesComponent: MosaicImagesComponent;
  @ViewChild('cropper') cropper: FileUploaderComponent;
  @ViewChild('editModal') editModal: CustomModalComponent;
  @Output() getImages = new EventEmitter();


  modalOptions: any;
  editModalOptions: any;
  imageSizes: any;
  selectedSize: any;
  selectedItem: ImageItemModel;
  showCropperBlock: boolean = false;
  showLoader: boolean = false;
  showEditLoader: boolean = false;
  imagesList: Array<ImageItemModel> = [];
  fileTitle: string = '';
  hasError: boolean = false;
  next: '';
  filterObject: any;


  constructor(private fileUploadService: FileUploadService, private popupHelperService: PopupHelperService) {
  }

  ngOnInit(): void {
    this.imageSizes = ImageSizes
    this.modalOptions = {
      size: "lg",
      centered: true,
      scrollable: true,
      modalDialogClass: 'user-images',
      backdropClass: '',
      backdrop: 'static',
    }
    this.editModalOptions = {
      size: "md",
      centered: true,
      scrollable: true,
      modalDialogClass: '',
      windowClass: '',
      backdropClass: '',
      backdrop: 'static',
    }
    this.selectedSize = ImageSizes[0];
    this.getList()
  }

  ngAfterViewInit(): void {
    const n = this.scrollWrapper.nativeElement.children.length;
    let totalWidth = 0
    ImageSizes.map(item => {
      totalWidth += Number(item.width)
    })
    this.scrollWrapper.nativeElement.style.setProperty('--total', String(totalWidth));
  }

  getImageSource(dataImg: string): void {
    this.onCreate(dataImg);
  }

  onChange(e: any): void {
    this.fileTitle = e.target.value;
  }

  onCreate(dataImg: string): void {

    if (!this.fileTitle || this.fileTitle === '') {
      this.hasError = true
    } else {
      this.showLoader = true;
      this.hasError = false;
      const newData = {
        file: dataImg,
        title: this.fileTitle,
        class: this.selectedSize.class,
      }
      this.fileUploadService.createFile(newData).subscribe((res: any) => {
        if (!res.hasError) {
          const {file} = res.data;
          this.imagesList.unshift(file);
          this.showLoader = false;
          this.showCropperBlock = false;
        }
      });
    }
  }

  getList(loadMore = false): void {
    this.showLoader = true;
    this.fileUploadService.getFilesList(this.filterObject).subscribe((res: any) => {
      if (!res.hasError) {
        this.showLoader = false;
        if(loadMore){
          this.imagesList = this.imagesList.concat(res.data.data);
        }else{
          this.imagesList =res.data.data;
        }
        this.next = res.data.next;
      }
      this.showLoader = false;
    });
  }

  onEdit(id: string): void {
    this.showEditLoader = true;
    this.hasError = false;
    this.fileUploadService.getFileById(id).subscribe((res: any) => {
      if (!res.hasError) {
        this.showEditLoader = false;
        this.editModal.openModal()
        this.selectedItem = res.data.file
      }
    });
  }


  openModal(): void {
    this.modal.openModal();
  }

  showCropperBlockFunc(isShow: boolean): void {
    this.fileTitle = '';
    this.showCropperBlock = isShow;
  }

  setSizeForFilter(data: any): void {
    this.filterObject={...this.filterObject,class: data.class,next: null}
    this.getList();
    this.selectedSize = data;
  }

  setSizeForCropper(data: any): void {
    this.selectedSize = data;
  }



  onUpdate(id: string): void {
    const title = this.fileTitle

    if (!this.fileTitle || this.fileTitle === ' ') {
      this.hasError = true;
    } else {
      this.fileUploadService.update({title: this.fileTitle}, id).subscribe((res: any) => {
        if (!res.hasError) {
          this.editModal.hide()
        }
      });
    }
  }

  loadMore(): void {

    this.filterObject={...this.filterObject,next: this.next}
    this.getList(true)

  }

  // before remove item open popup
  openPopup(item: ImageItemModel): void {
    const popupSettings = {
      customClass: {
        container:"z-index-9999",
        popup: "bg-000 w-15",
        confirmButton: "pink-button-imp",
        htmlContainer: "color-white",
      },
      icon: 'question',
      text: 'Are You sure ?',
      confirmButtonText: 'Yes',
    }
    this.popupHelperService.printQuestion(popupSettings, item.id, this.onDelete, this)
  }


  onDelete(id: string): void {
    // get selected item index from
    const index = this.mosaicImagesComponent.selectedItemIndex;
    const indexOfArr = this.mosaicImagesComponent.selectedItemsIndexArr.indexOf(index);

    this.fileUploadService.delete(id).subscribe((res: any) => {
      if (!res.hasError) {
        this.imagesList = this.imagesList.filter((item) => item.id !== id);
        this.mosaicImagesComponent.selectedItemsIndexArr.splice(indexOfArr, 1);
        this.mosaicImagesComponent.selectedImages.splice(indexOfArr, 1);
        this.editModal.hide()
      }
    })
  }


  onSetImages(): void {
    this.getImages.emit(this.mosaicImagesComponent.selectedImages);
    this.modal.hide();
  }

}
