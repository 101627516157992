import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {EventManager} from "./core/helpers/event-manager";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private eventService: EventManager) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.eventService.notificationShowEmitter.subscribe(item => this.addToast(item));
  }

  addToast(options) {

  }

}
